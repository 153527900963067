import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import moment from "moment"
import AppApi from "../utils/AppApi"

import {
  Container,
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
} from "@mui/material"

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts"

// const lower_southern_poly = []
// const upper_southern_poly = []
// const central_poly = []
// const eastern_poly = []
// const norst_east_poly = []

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={10}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
        fontSize="smaller"
      >
        {payload.value}
      </text>
    </g>
  )
}

const Home = () => {
  const user_data = useSelector((state) => state.auth.user)
  const [topDriver, setTopDriver] = useState(null)
  const [topTruck, setTopTruck] = useState(null)
  const [countTopDriver, setCountTopDriver] = useState(null)
  const [countTopTruck, setCountTopTruck] = useState(null)
  //const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const getData = async () => {
      try {
        let result = await AppApi.post("/dashboard", {
          viewMonth: moment().format("YYMM"),
        })
        console.log(result.data)

        let _topDriver = result.data.topDriver
          .map((data) => ({
            name: data._id,
            count: data.count,
          }))
          .slice(0, 20)

        let _topTruck = result.data.topTruck
          .map((data) => ({
            name: data._id,
            count: data.count,
          }))
          .slice(0, 20)

        //console.log(_topDriver)

        setTopDriver(_topDriver)
        setTopTruck(_topTruck)
        setCountTopDriver(result.data.countTopDriver)
        setCountTopTruck(result.data.countTopTruck)
        //setIsLoading(false)
        //return result.data
      } catch (error) {
        //  setIsLoading(false)
        console.log(error.message)
      }
    }

    getData()

    const interval = setInterval(() => {
      getData()
    }, 600000)

    return () => clearInterval(interval)
  }, [])

  return (
    <React.Fragment>
      <Box sx={{ pt: 8, pb: 8 }}>
        <Container maxWidth="sm">
          <Typography
            component="h1"
            variant="h3"
            align="center"
            gutterBottom
            color="text.primary"
          >
            Welcome {user_data.fullname} ,
          </Typography>
          <Typography variant="h4" align="center" color="text.secondary">
            to TMS Dashboard System
          </Typography>
        </Container>
      </Box>
      {user_data.roles.includes("Admin") && (
        <Container maxWidth="lg" sx={{ display: { xs: "none", md: "grid" } }}>
          <Grid container space={4}>
            <Grid item xs={12} lg={6}>
              <div>
                <h2>Top Driver DN of the month</h2>
              </div>
              <BarChart
                width={600}
                height={400}
                data={topDriver}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  height={90}
                  interval={0}
                  tick={<CustomizedAxisTick />}
                />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="count" fill="#8884d8" />
              </BarChart>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div>
                <h2>Count Driver DN Statistic</h2>
              </div>
              <BarChart
                width={600}
                height={400}
                data={countTopDriver}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="count" height={90} interval={0} />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="val" fill="#8884d8" />
              </BarChart>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div>
                <h2>Top Truck DN of the month</h2>
              </div>
              <BarChart
                width={600}
                height={400}
                data={topTruck}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  height={90}
                  interval={0}
                  tick={<CustomizedAxisTick />}
                />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="count" fill="#8884d8" />
              </BarChart>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div>
                <h2>Count Truck DN Statistic</h2>
              </div>
              <BarChart
                width={600}
                height={400}
                data={countTopTruck}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="count" height={90} interval={0} />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="val" fill="#8884d8" />
              </BarChart>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Card>
                <CardContent>
                  <Typography variant="h5">Announcement:</Typography>
                  <Typography>
                    <ul>
                      <li>
                        Best view on PC , the mobile version is under
                        developing.
                      </li>
                      <li>User need to re-login after 90 days.</li>
                      <li>Customer can be viewed only own job DN.</li>
                      <li>
                        User can be viewed the current truck location from the
                        dashboard page by click on the truck number{" "}
                      </li>
                    </ul>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      )}
    </React.Fragment>
  )
}

export default Home
