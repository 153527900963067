import React from "react"
import { Link } from "react-router-dom"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"

import MenuIcon from "@mui/icons-material/Menu"

import { useSelector, useDispatch } from "react-redux"
import { signOut } from "../reducers/authSlice"
import { Avatar, Container, IconButton, Menu, MenuItem } from "@mui/material"

//import { useLocation } from "react-router-dom"
import NavMenu from "./NavMenu"

const AppNav = (props) => {
  const user_data = useSelector((state) => state.auth.user)
  const refresh_token = useSelector((state) => state.auth.refreshToken)

  const dispatch = useDispatch()
  // const location = useLocation()

  const [anchorElNav, setAnchorElNav] = React.useState(null)

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const user_menu = [
    { label: "Home", link: "/" },
    { label: "Dashboard", link: "/dashboard" },
  ]
  const admin_menu = [
    { label: "Home", link: "/" },
    { label: "Dashboard", link: "/dashboard" },
    { label: "ETA Board", link: "/truckinyard" },
    { label: "Map View", link: "/mapview" },
    { label: "Driver Rpt", link: "/report_driver" },
    { label: "Warp Rpt", link: "/report_warp" },
    { label: "Traccar", link: "/report_traccar" },
    { label: "Truck Ready", link: "/truck-ready" },
  ]

  var display_menu = []

  //console.log(user_data)

  if (user_data.roles.includes("Guest")) {
    display_menu = [...user_menu]
  } else {
    display_menu = [...admin_menu]
  }

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar>
          <Box sx={{ display: { md: "flex", lg: "none" } }}>
            <IconButton
              size="large"
              color="inherit"
              aria-label="user menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {display_menu.map((menu) => (
                <MenuItem key={menu.link} onClick={handleCloseNavMenu}>
                  <Button color="inherit" component={Link} to={menu.link}>
                    {menu.label}
                  </Button>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h6"
            component="div"
            fontWeight="bold"
            sx={{ flexGrow: 1 }}
          >
            TMS Dashboard
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              marginLeft: "15px",
              display: { xs: "none", md: "flex" },
            }}
          >
            <NavMenu menu={display_menu} />
            {/* {display_menu.map((menu) => (
              <Button
                color={location.pathname === menu.link ? "warning" : "inherit"}
                component={Link}
                to={menu.link}
                key={menu.link}
              >
                {menu.label}
              </Button>
            ))} */}
          </Box>
          <Avatar>{user_data.fullname.substring(0, 1)}</Avatar>
          <Button
            color="inherit"
            onClick={(e) => {
              e.preventDefault()
              dispatch(signOut(refresh_token))
            }}
          >
            Sign out
          </Button>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default AppNav
