import React from 'react'

import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import TruckMap from './TruckMap'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const TruckMapDialog = (props) => {
  const { open, location, data, handleClose } = props

  console.log(data)

  return (
    <Dialog fullScreen open={open} TransitionComponent={Transition}>
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">{data ? data.vehicleName : ''}</Typography>
        </Toolbar>
      </AppBar>
      {data && <TruckMap location={location} data={data} />}
    </Dialog>
  )
}

export default TruckMapDialog
