import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { tryLogin } from "./reducers/authSlice"
import "./App.css"
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom"
import DnBoard from "./pages/DnBoard"
//import Dashboard from './components/Dashboard'
import Dn from "./pages/Dn"
import TruckList from "./pages/TruckList"
import MapView from "./pages/MapView"
import TruckView from "./pages/TruckView"
import TruckInYard from "./pages/TruckInYard"
import AppNav from "./components/AppNav"
import React from "react"
import DnTrack from "./pages/DnTrack"
import SignInSide from "./components/auth/SignInSide"
import Home from "./pages/Home"
import ReportDriver from "./pages/ReportDriver"
import ReportWarp from "./pages/ReportWarp"
import ReportTraccar from "./pages/ReportTraccar"
import TruckReady from "./pages/TruckReady"

function App() {
  // Initial Authentication State
  // const user_data = useSelector((state) => state.auth.user)
  // const isLogedIn = useSelector((state) => state.auth.isLogedIn)
  const dispatch = useDispatch()
  //let location = useLocation()
  useEffect(() => {
    dispatch(tryLogin())
  }, [dispatch])

  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route path="/auth">
            <Route path="/auth/login">
              <SignInSide />
            </Route>
          </Route>
          <PrivateRoute path="/">
            <AppNav />
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/board">
              <DnBoard />
            </Route>
            <Route path="/dashboard">
              <DnTrack />
            </Route>
            <Route path="/legacy_dashboard">
              <Dn />
            </Route>
            <Route path="/trucklist">
              <TruckList />
            </Route>
            <Route path="/mapview">
              <MapView />
            </Route>
            <Route path="/truckview">
              <TruckView />
            </Route>
            <Route path="/truckinyard">
              <TruckInYard />
            </Route>
            <Route path="/report_driver">
              <ReportDriver />
            </Route>
            <Route path="/report_warp">
              <ReportWarp />
            </Route>
            <Route path="/report_traccar">
              <ReportTraccar />
            </Route>
            <Route path="/truck-ready">
              <TruckReady />
            </Route>
          </PrivateRoute>
        </Switch>
      </Router>
    </React.Fragment>
  )
}

function PrivateRoute({ children, ...rest }) {
  const isLogedIn = useSelector((state) => state.auth.isLogedIn)
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLogedIn === null ? (
          <div>Loading</div>
        ) : isLogedIn === true ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/auth/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export default App
