import {
  Badge,
  Container,
  FormControlLabel,
  Stack,
  Switch,
} from "@mui/material"
import AppApi from "../utils/AppApi"
import moment from "moment"
import { useEffect, useState } from "react"
import Grid from "@mui/material/Unstable_Grid2"

import Paper from "@mui/material/Paper"
import { styled } from "@mui/material/styles"
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"
import * as Turf from "@turf/turf"

const MA_polygon = Turf.polygon([
  [
    [99.5742203591609, 11.210034766476738],
    [99.57288376878438, 11.210588980532037],
    [99.57233564023858, 11.209567406036129],
    [99.5741824118009, 11.20887670382588],
    [99.57461248189111, 11.20963358101153],
    [99.57656044641504, 11.210369776592657],
    [99.57508471571515, 11.211188688574993],
    [99.5742203591609, 11.210034766476738],
  ],
])

const MA_polygon2 = Turf.polygon([
  [
    [100.02570944275374, 13.412471914661225],
    [100.02523913243658, 13.412079786230763],
    [100.02690330740376, 13.410325255032433],
    [100.02743046841863, 13.410737495606114],
    [100.02570944275374, 13.412471914661225],
  ],
])

const TruckReady = () => {
  const [data, setData] = useState([])
  const [provinceList, setProvinceList] = useState([])
  const [updated, setUpdated] = useState(moment().format("DD/MM/YYYY HH:mm:ss"))
  const [showDetail, setShowDetail] = useState(false)
  const [provinceGroup, setProvinceGroup] = useState(true)
  // const [maTrucks, setMaTrucks] = useState(["L01"])

  useEffect(() => {
    const getData = async () => {
      let result = await AppApi.get("/truck_status")
      setData(result.data)
      setUpdated(moment().format("DD/MM/YYYY HH:mm:ss"))
      setProvinceList([
        ...new Set(result.data.map((i) => i.province[0].province)),
      ])
    }

    getData()
    const interval = setInterval(() => {
      getData()
    }, 60000)

    return () => clearInterval(interval)
  }, [provinceGroup])

  return (
    <Container maxWidth="xl" style={{ paddingBottom: "20px" }}>
      <h1>Real-Time Truck Ready</h1>
      <div style={{ marginBottom: "10px" }}>Last Update: {updated}</div>
      <div style={{ marginBottom: "10px" }}>
        Available :{" "}
        {
          data.filter(
            (i) =>
              i.lastDnStatus === "discharged" || i.lastDnStatus === "closed"
          ).length
        }{" "}
        Not Available :{" "}
        {
          data.filter(
            (i) =>
              !(i.lastDnStatus === "discharged" || i.lastDnStatus === "closed")
          ).length
        }
      </div>
      <Stack spacing={2} direction="row">
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={showDetail}
                onChange={() => setShowDetail(!showDetail)}
              />
            }
            label="Detail"
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={provinceGroup}
                onChange={() => setProvinceGroup(!provinceGroup)}
              />
            }
            label="Province Group"
          />
        </div>
      </Stack>

      {provinceGroup && (
        <div>
          {provinceList
            .sort((a, b) => a.localeCompare(b, "th"))
            .map((p) => (
              <div key={p}>
                <h1>{p}</h1>
                <Grid container spacing={1}>
                  {data
                    .filter((i) => i.province[0].province === p)
                    .map((t) => (
                      <ItemBox
                        data={t}
                        showDetail={showDetail}
                        key={t.truck_no}
                      />
                    ))}
                </Grid>
              </div>
            ))}
        </div>
      )}

      {!provinceGroup && (
        <Grid container spacing={1}>
          {data.map((t) => (
            <ItemBox data={t} showDetail={showDetail} key={t.truck_no} />
          ))}
        </Grid>
      )}
    </Container>
  )
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "start",
  color: theme.palette.text.secondary,
}))

const ItemBox = ({ data, showDetail = true, maTrucks = [] }) => {
  let point = Turf.point([data.longitude, data.latitude])
  let isMA =
    Turf.booleanPointInPolygon(point, MA_polygon) ||
    Turf.booleanPointInPolygon(point, MA_polygon2)

  return (
    <>
      {showDetail && (
        <Grid xs={12} md={6} lg={3}>
          <Item
            sx={{
              backgroundColor:
                data.lastDnStatus === "discharged" ||
                data.lastDnStatus === "closed"
                  ? "green"
                  : "red",
              color: "#fff",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ fontSize: "24pt", fontWeight: "bold" }}>
                {data.truck_no}
              </div>
              <div style={{ fontSize: "14pt", fontWeight: "bold" }}>
                {data.lastDnStatus === "discharged" ||
                data.lastDnStatus === "closed"
                  ? "Available"
                  : "On Working"}
              </div>
            </div>

            <div
              style={{
                minHeight: "60px",
                backgroundColor: "#FCF5ED",
                color: "black",
                padding: "5px",
              }}
            >
              {data.address}
            </div>
          </Item>
        </Grid>
      )}
      {!showDetail && (
        <Grid lg={1} md={2} xs={3}>
          <Badge
            badgeContent={
              data.lastDnStatus.charAt(0).toUpperCase() +
              data.lastDnStatus.slice(1)
            }
            color={
              data.lastDnStatus === "open"
                ? "error"
                : data.lastDnStatus.charAt(0).toUpperCase() === "D"
                ? "primary"
                : "warning"
            }
            invisible={
              data.lastDnStatus === "discharged" ||
              data.lastDnStatus === "closed"
            }
            sx={{ width: "100%" }}
          >
            <HtmlTooltip title={<>{data.address}</>}>
              <Item
                sx={{
                  backgroundColor: isMA
                    ? "gray"
                    : data.lastDnStatus === "discharged" ||
                      data.lastDnStatus === "closed"
                    ? "green"
                    : "red",
                  color: "#fff",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    fontSize: "24pt",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  {data.truck_no}
                </div>
              </Item>
            </HtmlTooltip>
          </Badge>
        </Grid>
      )}
    </>
  )
}

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))

export default TruckReady
