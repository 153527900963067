import { Button } from "@mui/material"
import { useLocation } from "react-router-dom"
import { Link } from "react-router-dom"

const NavMenu = ({ menu }) => {
  const location = useLocation()

  return (
    <>
      {menu.map((menu) => (
        <Button
          //color={location.pathname === menu.link ? "warning" : "inherit"}
          color="inherit"
          component={Link}
          to={menu.link}
          key={menu.link}
          sx={{
            borderBottomWidth: location.pathname === menu.link ? "3px" : "0px",
            borderBottomStyle: "solid",
            borderBottomColor: "white",
          }}
        >
          {menu.label}
        </Button>
      ))}
    </>
  )
}

export default NavMenu
