import React from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

export default function Map(props) {
  const position = props.data.latitude
    ? [props.data.latitude, props.data.longitude]
    : [13.638468, 100.557651]

  return (
    <div className="map__container">
      <MapContainer center={position} zoom={12} style={{ height: '100vh' }}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position}>
          <Popup>
            {props.data.vehicleName} - {props.data.address}
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  )
}
