import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import AppApi from '../utils/AppApi'
import { AppAuthApi } from '../utils/AppApi'

export const signIn = createAsyncThunk('auth/signIn', async (payload) => {
  try {
    const response = await AppApi.post('/auth/login', {
      username: payload.username,
      password: payload.password,
    })

    return response.data
  } catch (error) {
    console.log('in error')
    console.log(error)
    return error
  }
})

export const signOut = createAsyncThunk('auth/signOut', async (payload) => {
  try {
    AppAuthApi.defaults.headers.common['x-auth-token'] = payload

    const response = await AppAuthApi.delete('/auth/logout')

    return response.data
  } catch (error) {
    return error
  }
})

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLogedIn: null,
    user: null,
    token: null,
    refreshToken: null,
    isError: null,
    errorMessage: null,
  },
  reducers: {
    LogedIn: (state, action) => {
      state.isLogedIn = true
    },

    setUser: (state, action) => {
      state.user = action.payload
    },
    setError: (state, action) => {
      state.isLogedIn = false
      state.isError = true
      state.errorMessage = action.payload
    },
    refreshToken: (state, action) => {
      localStorage.setItem('token', action.payload.token)
      state.token = action.payload.token
    },
    tryLogin: (state, action) => {
      const user_data = JSON.parse(localStorage.getItem('user_data'))
      const token = localStorage.getItem('token')
      const refreshToken = localStorage.getItem('refresh_token')
      if (user_data) {
        state.user = user_data
        state.token = token
        state.isLogedIn = true
        state.refreshToken = refreshToken
        //AppApi.defaults.headers.common['x-auth-token'] = token
      } else {
        state.isLogedIn = false
      }
    },
  },
  extraReducers: (builder) => {
    //Signin
    builder.addCase(signIn.fulfilled, (state, action) => {
      if (typeof action.payload.accessToken === 'undefined') {
        //Error Occure Case
        state.isLogedIn = false
        state.isError = true
        console.log(action.payload)
        state.errorMessage = action.payload.response.data.error
      } else {
        // Normal Login Case
        localStorage.setItem(
          'user_data',
          JSON.stringify(action.payload.user_data)
        )
        localStorage.setItem('token', action.payload.accessToken)
        localStorage.setItem('refresh_token', action.payload.refreshToken)

        // AppApi.defaults.headers.common['x-auth-token'] =
        //   action.payload.accessToken

        state.token = action.payload.accessToken
        state.refreshToken = action.payload.refreshToken
        state.user = action.payload.user_data
        state.isLogedIn = true
        state.isError = false
        state.errorMessage = null
      }
    })

    //Signout
    builder.addCase(signOut.fulfilled, (state) => {
      localStorage.removeItem('token')
      localStorage.removeItem('refresh_token')
      localStorage.removeItem('user_data')
      state.token = null
      state.refreshToken = null
      state.user = null
      state.isLogedIn = false
    })
  },
})

export const { LogedIn, setUser, tryLogin, setError, refreshToken } =
  authSlice.actions

export default authSlice.reducer
