import { useEffect, useState } from 'react'

import { Container } from '@mui/material'
import AppApi from '../utils/AppApi'
import moment from 'moment'

const ReportWarp = () => {
  const [data, setData] = useState({ last_warp: [], top_ten: [] })

  useEffect(() => {
    const getData = async () => {
      let result = await AppApi.get('/rpt_warp')

      console.log(result.data)
      setData(result.data)
    }

    getData()

    const interval = setInterval(() => {
      getData()
    }, 60000)

    return () => clearInterval(interval)
  }, [])

  return (
    <Container maxWidth="xl">
      <h1>GPS Warp Report</h1>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}
      >
        <div>
          <h2>Lastest Event</h2>
          <div>
            {data.last_warp.map((row) => (
              <li key={row.create_at}>
                {moment(row.create_at).local().format('YYYY-MM-DD HH:mm:ss')} -{' '}
                {row.truck_no} - {row.warp_distance}
              </li>
            ))}
          </div>
        </div>
        <div>
          <h2>Top Warp Event (Last 30 Days)</h2>
          <div>
            {data.top_ten
              .sort((a, b) => b.countEvent - a.countEvent)
              .map((row, i) => (
                <div key={row._id}>
                  {i + 1}. {row._id} - {row.countEvent}
                </div>
              ))}
          </div>
        </div>
        <div>
          <h2>Top Warp Distance (Last 30 Days)</h2>
          <div>
            {data.top_ten
              .sort((a, b) => b.sumDistance - a.sumDistance)
              .map((row, i) => (
                <div key={row._id}>
                  {i + 1}. {row._id} - {row.sumDistance}
                </div>
              ))}
          </div>
        </div>
      </div>
    </Container>
  )
}

export default ReportWarp
