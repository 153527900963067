import React, { useState, useEffect, useRef } from "react"

// Material UI interface
import { makeStyles } from "@material-ui/core/styles"
import { Toolbar, AppBar, Typography } from "@material-ui/core"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"

//Other Utility
//import axios from 'axios'
import moment from "moment"

import AppApi from "../utils/AppApi"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  gridroot: { flexGrow: 1 },
  title: {
    flexGrow: 1,
    color: "black",
  },
  appbar: {
    backgroundColor: "#ffeb3b",
  },
  paper: {
    height: "25px",
    padding: "5px",
  },
}))

const calDistance = (lat1, lon1, lat2, lon2, unit) => {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0
  } else {
    var radlat1 = (Math.PI * lat1) / 180
    var radlat2 = (Math.PI * lat2) / 180
    var theta = lon1 - lon2
    var radtheta = (Math.PI * theta) / 180
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)

    if (dist > 1) {
      dist = 1
    }
    dist = Math.acos(dist)
    dist = (dist * 180) / Math.PI
    dist = dist * 60 * 1.1515
    if (unit === "K") {
      dist = dist * 1.609344
    }
    if (unit === "N") {
      dist = dist * 0.8684
    }
    return dist
  }
}

export default function DnBoard() {
  const classes = useStyles()
  const [dn, setDn] = useState([])
  //const [objDn,setObjDn] = useState()
  //const [truckNo, setTruckNo] = useState('')
  const [viewDate] = useState(new Date())
  const [filteredDn, setFilteredDn] = useState({
    total: 0,
    open_dn: [],
    loading_dn: [],
    loaded_dn: [],
    discharge_dn: [],
    discharged_dn: [],
  })
  //const [isLoading, setIsLoading] = useState(false)
  const [trucks, setTrucks] = useState([])
  const [page, setPage] = useState(0)

  const endEl = useRef(null)
  const startEl = useRef(null)

  useEffect(() => {
    const getDn = async () => {
      //setIsLoading(true)
      try {
        let response = await AppApi.post("/dn_status", {
          start_date: moment(viewDate).subtract(1, "days").format("DD/MM/YYYY"),
          end_date: moment(viewDate).format("DD/MM/YYYY"),
        })

        console.log("dn_status", response.data)

        setDn(response.data)
        setFilteredDn(response.data)
        //setIsLoading(false)
      } catch (error) {
        console.log(error.message)
        //setIsLoading(false)
      }
    }

    const getTrucks = async () => {
      try {
        let response = await AppApi.get("/truck_location")

        setTrucks(response.data)
      } catch (error) {
        console.log(error.message)
      }
    }

    getDn()
    getTrucks()

    const interval = setInterval(() => {
      getDn()
      getTrucks()
    }, 60000)

    return () => clearInterval(interval)
  }, [viewDate])

  useEffect(() => {
    let i = 0
    const interval = setInterval(() => {
      i++
      if (i > 4) {
        i = 0
      }

      setPage(i)
    }, 20000)

    return () => clearInterval(interval)
  }, [])

  // แยกประเภท DN
  useEffect(() => {
    if (dn.length !== 0) {
      console.log("DN Change")
      let result = [...dn]
      // let isFilter = false

      let open_dn = []
      let loading_dn = []
      let loaded_dn = []
      let discharge_dn = []
      let discharged_dn = []

      result.forEach((r) => {
        //check discharge first

        let arr_discharged = r.discharges.filter(
          (d) => d.discharge_status === 2
        )

        if (arr_discharged.length > 0) {
          discharged_dn.push(r)
          return
        }

        let arr_discharge = r.discharges.filter((d) => {
          return d.discharge_status >= 0 && d.discharge_status <= 1
        })
        if (arr_discharge.length > 0) {
          discharge_dn.push(r)
          return
        }

        if (r.loading_status === -1) {
          open_dn.push(r)
          return
        }

        if (r.loading_status === 2) {
          loaded_dn.push(r)
          return
        }

        loading_dn.push(r)
      })

      //TODO: ตรวจสอบเอาข้อมูลรถจบงานที่มีงานใหม่แล้วออก
      var today_truck_list = [
        ...open_dn.map((d) => d.truck_no),
        ...loading_dn.map((d) => d.truck_no),
        ...loaded_dn.map((d) => d.truck_no),
        ...discharge_dn.map((d) => d.truck_no),
      ]

      console.log("today_truck_list", today_truck_list)

      today_truck_list.forEach((t) => {
        var result = discharged_dn.findIndex((d) => {
          return d.truck_no === t
        })
        if (result !== -1) {
          console.log("duplicate truck " + t)
          discharged_dn.splice(result, 1)
        }
      })

      let finish_dn = {
        total: result.length,
        open_dn,
        loading_dn,
        loaded_dn,
        discharge_dn,
        discharged_dn,
      }

      console.log(finish_dn)

      setFilteredDn(finish_dn)
    }
  }, [dn])

  useEffect(() => {
    startEl.current.scrollIntoView({ behavior: "smooth" })

    setTimeout(
      () => endEl.current.scrollIntoView({ behavior: "smooth" }),
      10000
    )
  }, [page])

  const ctruck = (data) => {
    if (data.length === 4) {
      return data
    } else {
      return "L0" + data.substring(1, 3)
    }
  }

  const pageName = [
    "At Origin รอขึ้นสินค้า",
    "On the Way ระหว่างเดินทางไปส่งสินค้า",
    "At Destination ถึงปลายทาง",
    "Finished Job จบงาน - เดินทางกลับ",
    "Available Truck  รถพร้อมรับงาน",
  ]

  const renderDetail = (dn, page) => {
    var status = dn.status

    const truck_loc = trucks.filter(
      (t) => t.vehicleName === ctruck(dn.truck_no)
    )

    var distance = calDistance(
      dn.loading_lat,
      dn.loading_lng,
      truck_loc[0].latitude,
      truck_loc[0].longitude
    )

    var isLastUpdate = false
    const isGpsFailed =
      moment().diff(moment(truck_loc.dateTime), "minutes", true) > 15
        ? true
        : false

    if ("arrival_loading_time" in dn) {
      status =
        "Ariving - " + moment(dn.arrival_loading_time).format("DD/MM/YY HH:mm")

      isLastUpdate =
        moment().diff(moment(dn.arrival_loading_time), "minutes", true) < 15
          ? true
          : false
    }

    if ("at_loading_time" in dn) {
      status =
        "Loading - " + moment(dn.at_loading_time).format("DD/MM/YY HH:mm")
      isLastUpdate =
        moment().diff(moment(dn.at_loading_time), "minutes", true) < 15
          ? true
          : false
    }

    if ("departed_loading_time" in dn) {
      status =
        "Departed - " +
        moment(dn.departed_loading_time).format("DD/MM/YY HH:mm")

      distance = calDistance(
        dn.discharges[0].discharge_lat,
        dn.discharges[0].discharge_lng,
        truck_loc[0].latitude,
        truck_loc[0].longitude
      )

      isLastUpdate =
        moment().diff(moment(dn.departed_loading_time), "minutes", true) < 15
          ? true
          : false
    }

    dn.discharges.forEach((dc) => {
      if ("arriving_discharge_time" in dc) {
        status =
          "Arring - " +
          dc.discharge_point +
          " - " +
          moment(dc.arriving_discharge_time).format("DD/MM/YY HH:mm")

        distance = calDistance(
          dc.discharge_lat,
          dc.discharge_lng,
          truck_loc[0].latitude,
          truck_loc[0].longitude
        )

        isLastUpdate =
          moment().diff(moment(dc.arriving_discharge_time), "minutes", true) <
          15
            ? true
            : false
      }

      if ("at_discharge_time" in dc) {
        status =
          "Discharging - " +
          dc.discharge_point +
          " - " +
          moment(dc.at_discharge_time).format("DD/MM/YY HH:mm")

        distance = calDistance(
          dc.discharge_lat,
          dc.discharge_lng,
          truck_loc[0].latitude,
          truck_loc[0].longitude
        )

        isLastUpdate =
          moment().diff(moment(dc.at_discharge_time), "minutes", true) < 15
            ? true
            : false
      }

      if ("departed_discharge_time" in dc) {
        status =
          "Departed - " +
          dc.discharge_point +
          " - " +
          moment(dc.departed_discharge_time).format("DD/MM/YY HH:mm")
        distance = calDistance(
          11.22996,
          99.54345,
          truck_loc[0].latitude,
          truck_loc[0].longitude
        )

        isLastUpdate =
          moment().diff(moment(dc.departed_discharge_time), "minutes", true) <
          15
            ? true
            : false
      }
    })

    // Filter out truck that park at YARDL
    if (page === 3 && distance < 5) {
      return
    }

    // Filter out truck that back to YARDL
    if (page === 4 && distance >= 5) {
      return
    }

    var bgColor = "#FFFFFF"
    if (page === 3 && distance < 50) {
      bgColor = "#a5d6a7"
    }

    if (isLastUpdate) {
      bgColor = "#4fc3f7"
    }

    //bgColor = isLastUpdate ? '#4fc3f7' : '#FFFFFF'

    return (
      <Grid
        container
        justifyContent="center"
        direction="row"
        spacing={1}
        style={{ marginTop: "1px", marginLeft: "1px", marginRight: "1px" }}
        key={dn.dn}
      >
        <Grid item style={{ width: "80px" }}>
          <Paper className={classes.paper} style={{ backgroundColor: bgColor }}>
            <center>{dn.truck_no}</center>
          </Paper>
        </Grid>
        <Grid item style={{ width: "120px" }}>
          <Paper className={classes.paper} style={{ backgroundColor: bgColor }}>
            <center>{dn.dn}</center>
          </Paper>
        </Grid>
        <Grid item style={{ width: "80px" }}>
          <Paper className={classes.paper} style={{ backgroundColor: bgColor }}>
            <center>{dn.loading_point}</center>
          </Paper>
        </Grid>
        <Grid item style={{ width: "200px" }}>
          <Paper className={classes.paper} style={{ backgroundColor: bgColor }}>
            <center>
              {dn.discharges.map((dis) => " " + dis.discharge_point)}
            </center>
          </Paper>
        </Grid>
        <Grid item style={{ width: "300px" }}>
          <Paper className={classes.paper} style={{ backgroundColor: bgColor }}>
            <center>{status}</center>
          </Paper>
        </Grid>
        <Grid item style={{ width: "80px" }}>
          <Paper className={classes.paper} style={{ backgroundColor: bgColor }}>
            <center>{truck_loc.length > 0 ? truck_loc[0].speed : "-"}</center>
          </Paper>
        </Grid>
        <Grid item style={{ width: "90px" }}>
          <Paper className={classes.paper} style={{ backgroundColor: bgColor }}>
            <center>{distance.toFixed(2)}</center>
          </Paper>
        </Grid>
        <Grid item className={classes.gridroot}>
          <Paper className={classes.paper} style={{ backgroundColor: bgColor }}>
            <center>
              {isGpsFailed ? "GPS Problem" : ""}{" "}
              {truck_loc.length > 0 ? truck_loc[0].address : "-"}
            </center>
          </Paper>
        </Grid>
      </Grid>
    )
  }

  return (
    <div
      style={{ backgroundColor: "#0d47a1", minHeight: "100vh" }}
      ref={startEl}
    >
      <AppBar position="sticky" className={classes.appbar}>
        <Toolbar>
          <Typography variant="h5" className={classes.title}>
            Truck Board - {pageName[page]}
          </Typography>
        </Toolbar>
      </AppBar>

      <Grid
        container
        justifyContent="center"
        direction="row"
        spacing={1}
        style={{ marginTop: "1px", marginLeft: "1px", marginRight: "1px" }}
      >
        <Grid item style={{ width: "80px" }}>
          <Paper className={classes.paper}>
            <center>Truck</center>
          </Paper>
        </Grid>
        <Grid item style={{ width: "120px" }}>
          <Paper className={classes.paper}>
            <center>DN</center>
          </Paper>
        </Grid>
        <Grid item style={{ width: "80px" }}>
          <Paper className={classes.paper}>
            <center>Origin</center>
          </Paper>
        </Grid>
        <Grid item style={{ width: "200px" }}>
          <Paper className={classes.paper}>
            <center>Destination</center>
          </Paper>
        </Grid>
        <Grid item style={{ width: "300px" }}>
          <Paper className={classes.paper}>
            <center>Status</center>
          </Paper>
        </Grid>
        <Grid item style={{ width: "80px" }}>
          <Paper className={classes.paper}>
            <center>Speed</center>
          </Paper>
        </Grid>
        <Grid item style={{ width: "90px" }}>
          <Paper className={classes.paper}>
            <center>Distance</center>
          </Paper>
        </Grid>
        <Grid item className={classes.gridroot}>
          <Paper className={classes.paper}>
            <center>Truck Location</center>
          </Paper>
        </Grid>
      </Grid>

      {page === 0 ? filteredDn.open_dn.map((dn) => renderDetail(dn, 0)) : null}
      {page === 0
        ? filteredDn.loading_dn.map((dn) => renderDetail(dn, 0))
        : null}
      {page === 1
        ? filteredDn.loaded_dn.map((dn) => renderDetail(dn, 1))
        : null}
      {page === 2
        ? filteredDn.discharge_dn.map((dn) => renderDetail(dn, 2))
        : null}
      {page === 3
        ? filteredDn.discharged_dn
            .sort((a, b) => (a.dn > b.dn ? -1 : b.dn > a.dn ? 1 : 0))
            .map((dn) => renderDetail(dn, 3))
        : null}
      {page === 4
        ? filteredDn.discharged_dn
            .sort((a, b) =>
              a.truck_no > b.truck_no ? 1 : b.truck_no > a.truck_no ? -1 : 0
            )
            .map((dn) => renderDetail(dn, 4))
        : null}
      <div ref={endEl}>--END--</div>
    </div>
  )
}
