import React, { useState, useEffect } from "react"

// Material UI interface
import { makeStyles, withStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import CircularProgress from "@material-ui/core/CircularProgress"
import Button from "@material-ui/core/Button"
import Tooltip from "@material-ui/core/Tooltip"
import Link from "@material-ui/core/Link"
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers"
import Typography from "@material-ui/core/Typography"
import Dialog from "@material-ui/core/Dialog"
import Slide from "@material-ui/core/Slide"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Switch from "@material-ui/core/Switch"
import FormControlLabel from "@material-ui/core/FormControlLabel"

import CloseIcon from "@material-ui/icons/Close"

//Other Utility
//import axios from 'axios'
import _ from "lodash"
import moment from "moment"
import DateFnsUtils from "@date-io/date-fns"

//Local Utility
import Title from "../components/Title"
import TruckMap from "../components/TruckMap"
import AppApi from "../utils/AppApi"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "290px",
    margin: "1px",
    padding: "10px",
    textAlign: "center",
  },
  boxRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    margin: "5px",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    padding: "10px",
  },
  borderBox: {
    borderWidth: "1px",
    borderColor: "grey",
  },
  headerBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    padding: "5px",
    backgroundColor: "red",
    color: "white",
  },
  subHeaderBox: {
    backgroundColor: "blue",
    color: "white",
  },
}))

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip)

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip)

const defaultProps = {
  bgcolor: "background.paper",
  borderColor: "text.primary",

  border: 1,
  flex: 1,
}

export default function Dn() {
  const classes = useStyles()
  const [dn, setDn] = useState([])
  //const [objDn,setObjDn] = useState()
  const [truckNo, setTruckNo] = useState("")
  const [viewDate, setViewDate] = useState(new Date())
  const [filteredDn, setFilteredDn] = useState({
    total: 0,
    open_dn: [],
    loading_dn: [],
    loaded_dn: [],
    discharge_dn: [],
    discharged_dn: [],
  })
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState("")
  const [discharge, setDischarge] = useState("")
  const [openDialog, setOpenDialog] = useState(false)
  const [data, setData] = useState({ vehicleName: "Dummy" })
  const [location] = useState([100, 100])
  const [hideDetail, setHideDetail] = useState(false)

  useEffect(() => {
    const getDn = async () => {
      setIsLoading(true)
      try {
        let response = await AppApi.post("/dn_status", {
          start_date: moment(viewDate).format("DD/MM/YYYY"),
          end_date: moment(viewDate).format("DD/MM/YYYY"),
        })

        let calculated_dn = response.data.map((dn) => {
          let total_time = 0

          if (dn.yard_reason) {
            let start_time = dn.arrival_loading_time
              ? dn.arrival_loading_time
              : dn.at_loading_time
              ? dn.at_loading_time
              : dn.departed_loading_time
              ? dn.departed_loading_time
              : 0

            if (start_time !== 0) {
              total_time = moment(dn.yard_time).diff(
                moment(start_time),
                "hours"
              )
            }
          }

          let new_dn = { ...dn, total_time }

          return new_dn
        })

        //console.log('cal', calculated_dn)
        setDn(calculated_dn)
        //setFilteredDn(response.data)
        //setIsLoading(false)
      } catch (error) {
        console.log(error.message)
        setIsLoading(false)
      }
    }

    getDn()

    const interval = setInterval(() => {
      getDn()
    }, 60000)

    return () => clearInterval(interval)
  }, [viewDate])

  useEffect(() => {
    if (dn.length !== 0) {
      console.log("DN Change")
      let result = [...dn]
      //let isFilter = false

      if (truckNo.length !== 0) {
        //isFilter = true
        result = _.filter(result, (d) => _.includes(d.truck_no, truckNo))
      }

      if (loading.length >= 3) {
        console.log("In Loading")
        //isFilter = true
        result = _.filter(result, (d) => _.includes(d.loading_point, loading))
      }

      if (discharge.length >= 3) {
        console.log("In Discharge")
        console.log(result)
        //isFilter = true
        result = _.filter(result, (d) => {
          let found = _.find(
            d.discharges,
            (dc) => dc.discharge_point === discharge
          )
          if (found !== undefined) {
            return true
          } else {
            return false
          }
        })
        console.log("after", result)
      }

      let open_dn = []
      let loading_dn = []
      let loaded_dn = []
      let discharge_dn = []
      let discharged_dn = []

      result.forEach((r) => {
        //check discharge first

        let arr_discharged = r.discharges.filter(
          (d) => d.discharge_status === 2
        )

        if (arr_discharged.length > 0) {
          discharged_dn.push(r)
          return
        }

        let arr_discharge = r.discharges.filter((d) => {
          return d.discharge_status >= 0 && d.discharge_status <= 1
        })
        if (arr_discharge.length > 0) {
          discharge_dn.push(r)
          return
        }

        if (r.loading_status === -1) {
          open_dn.push(r)
          return
        }

        if (r.loading_status === 2) {
          loaded_dn.push(r)
          return
        }

        loading_dn.push(r)
      })

      let finish_dn = {
        total: result.length,
        open_dn,
        loading_dn,
        loaded_dn,
        discharge_dn,
        discharged_dn,
      }

      console.log(finish_dn)

      setFilteredDn(finish_dn)
    } else {
      setFilteredDn({
        total: 0,
        open_dn: [],
        loading_dn: [],
        loaded_dn: [],
        discharge_dn: [],
        discharged_dn: [],
      })
    }
    setIsLoading(false)
  }, [dn, truckNo, loading, discharge])

  const showTablet = (id) => {
    return (
      <LightTooltip title={id} placement="right">
        <img src="tablet.png" width="24" height="24" alt={id}></img>
      </LightTooltip>
    )
  }

  const showLoadingDetail = (d) => {
    if ("loading_name" in d) {
      return (
        <HtmlTooltip
          title={
            <React.Fragment>
              <Typography variant="h6">{d.loading_name}</Typography>
              <Typography variant="body1">{d.loading_addr}</Typography>
              <Typography variant="body2">
                {d.loading_lat},{d.loading_lng}
              </Typography>
            </React.Fragment>
          }
        >
          <Link
            target="_blank"
            rel="noreferrer"
            href={`https://www.google.com/maps?q=${d.loading_lat},${d.loading_lng}`}
          >
            {d.loading_point}
          </Link>
        </HtmlTooltip>
      )
    }

    return d.loading_point
  }

  const showDischargeDetail = (dc) => {
    if ("discharge_name" in dc) {
      return (
        <HtmlTooltip
          title={
            <React.Fragment>
              <Typography variant="h6">{dc.discharge_name}</Typography>
              <Typography variant="body1">{dc.discharge_addr}</Typography>
              <Typography variant="body2">
                {dc.discharge_lat},{dc.discharge_lng}
              </Typography>
            </React.Fragment>
          }
        >
          <Link
            target="_blank"
            rel="noreferrer"
            href={`https://www.google.com/maps?q=${dc.discharge_lat},${dc.discharge_lng}`}
          >
            {dc.discharge_point}
          </Link>
        </HtmlTooltip>
      )
    }

    return dc.discharge_point
  }

  const renderDnCard = (d, handleOpen) => {
    // let total_time = 0
    // if (d.yard_reason) {
    //   let start_time = d.arrival_loading_time
    //     ? d.arrival_loading_time
    //     : d.at_loading_time
    //     ? d.at_loading_time
    //     : d.departed_loading_time
    //     ? d.departed_loading_time
    //     : 0

    //   if (start_time !== 0) {
    //     total_time = moment(d.yard_time).diff(moment(start_time), 'hours')
    //   }
    // }

    return (
      <Paper key={d.dn}>
        {hideDetail && (
          <React.Fragment>
            <Box>
              <b>
                {"[" +
                  d.truck_no +
                  "] " +
                  d.dn +
                  " " +
                  d.loading_point +
                  " => "}
                {d.discharges.map((dc) => (
                  <React.Fragment>{dc.discharge_point + " "}</React.Fragment>
                ))}
              </b>
            </Box>
          </React.Fragment>
        )}
        {!hideDetail && (
          <React.Fragment>
            <Box
              bgcolor={"red"}
              p={1}
              flexDirection="row"
              display="flex"
              justifyContent="space-between"
              color={"white"}
            >
              <div>DN: {d.dn} </div>
              {"tablet_id" in d
                ? d.tablet_id
                  ? showTablet(d.tablet_id)
                  : null
                : null}
              <div>
                Truck:{" "}
                <Link
                  color="inherit"
                  onClick={() =>
                    handleOpen({ truck_no: d.truck_no, current_dn: { ...d } })
                  }
                >
                  {d.truck_no}
                </Link>
              </div>
            </Box>
            <div className={classes.boxRow}>
              <Box {...defaultProps} marginRight={"5px"}>
                <Box bgcolor={"blue"} color={"white"} p={1}>
                  Loading
                </Box>

                <div className={classes.center}>{showLoadingDetail(d)}</div>
                <Box m={0}>
                  <Box bgcolor={"yellow"} p={1}>
                    Arriving
                  </Box>
                  <Box p={1}>
                    {"arrival_loading_time" in d
                      ? moment(d.arrival_loading_time).format("DD/MM/YY HH:mm")
                      : "-"}
                  </Box>
                  <Box bgcolor={"yellow"} p={1}>
                    At Loading
                  </Box>
                  <Box p={1}>
                    {"at_loading_time" in d
                      ? moment(d.at_loading_time).format("DD/MM/YY HH:mm")
                      : "-"}
                  </Box>
                  <Box p={1} bgcolor={"yellow"}>
                    Departed
                  </Box>
                  <Box p={1}>
                    {"departed_loading_time" in d
                      ? moment(d.departed_loading_time).format("DD/MM/YY HH:mm")
                      : "-"}
                  </Box>

                  {d.yard_reason && (
                    <React.Fragment>
                      <Box p={1} bgcolor={"orange"}>
                        Total Hour
                      </Box>
                      <Box p={1}>{d.total_time}</Box>
                    </React.Fragment>
                  )}
                </Box>
              </Box>
              <Box {...defaultProps}>
                <Box bgcolor={"green"} color={"white"} p={1}>
                  Discharge
                </Box>
                {d.discharges.map((dc) => {
                  return (
                    <div key={dc.discharge_point}>
                      <div className={classes.center}>
                        {showDischargeDetail(dc)}
                      </div>
                      <Box m={0}>
                        <Box p={1} bgcolor={"yellow"}>
                          Arriving
                        </Box>
                        <Box p={1}>
                          {"arriving_discharge_time" in dc
                            ? moment(dc.arriving_discharge_time).format(
                                "DD/MM/YY HH:mm"
                              )
                            : "-"}
                        </Box>
                        <Box p={1} bgcolor={"yellow"}>
                          At Discharge
                        </Box>
                        <Box p={1}>
                          {"at_discharge_time" in dc
                            ? moment(dc.at_discharge_time).format(
                                "DD/MM/YY HH:mm"
                              )
                            : "-"}
                        </Box>
                        <Box p={1} bgcolor={"yellow"}>
                          Departed
                        </Box>
                        <Box p={1}>
                          {"departed_discharge_time" in dc
                            ? moment(dc.departed_discharge_time).format(
                                "DD/MM/YY HH:mm"
                              )
                            : "-"}
                        </Box>

                        {d.yard_reason && (
                          <React.Fragment>
                            <Box p={1} bgcolor={"orange"}>
                              {d.yard_reason}
                            </Box>
                            <Box p={1}>
                              {moment(d.yard_time).format("DD/MM/YY HH:mm")}
                            </Box>
                          </React.Fragment>
                        )}
                      </Box>
                    </div>
                  )
                })}
              </Box>
            </div>
          </React.Fragment>
        )}
      </Paper>
    )
  }

  const handleClose = () => {
    console.log("handle Close")
    setOpenDialog(false)
  }

  const handleOpen = async (data) => {
    console.log("handle Open", data.truck_no)

    let response = await AppApi.post("/truck_location", {
      truck_no: data.truck_no,
    })

    let latitude = response.data[0].latitude
    let longitude = response.data[0].longitude
    let address = response.data[0].address
    let speed = response.data[0].speed
    let show_dn =
      response.data[0].dn.length > 10 ? 10 : response.data[0].dn.length
    let dn_list = response.data[0].dn.slice(-1 * show_dn)

    setData({
      vehicleName: data.truck_no,
      latitude,
      longitude,
      address,
      dn_list,
      speed,
      current_dn: data.current_dn,
    })
    setOpenDialog(true)
  }

  return (
    <React.Fragment>
      <div style={{ margin: "15px" }}>
        <Grid container spacing={2} direction="row">
          <Grid item>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                label="Select Date to view"
                value={viewDate}
                onChange={setViewDate}
                format="dd/MM/yyyy"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item>
            <TextField
              id="truck-no-textbox"
              label="Truck No"
              value={truckNo}
              onChange={(e) => setTruckNo(e.target.value)}
            />
          </Grid>

          <Grid item>
            <TextField
              id="loading-textbox"
              label="Loading Point"
              value={loading}
              onChange={(e) => setLoading(e.target.value.toUpperCase())}
            />
          </Grid>

          <Grid item>
            <TextField
              id="discharge-textbox"
              label="Discharge Point"
              value={discharge}
              onChange={(e) => setDischarge(e.target.value.toUpperCase())}
            />
          </Grid>

          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  checked={hideDetail}
                  onChange={() => setHideDetail(!hideDetail)}
                  name="checkedHideDetail"
                  color="primary"
                />
              }
              label="Hide Detail"
            />
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setTruckNo("")
                setLoading("")
                setDischarge("")
              }}
            >
              Clear
            </Button>
          </Grid>

          {isLoading ? (
            <Grid item>
              <CircularProgress color="primary" />
              Loading...
            </Grid>
          ) : null}
        </Grid>
      </div>

      <Title>DN Status</Title>
      <Grid container spacing={2}>
        <Grid item xs>
          <Grid item xs={12}>
            <div style={{ textAlign: "center" }}>
              <Title>
                Open ({filteredDn.open_dn.length}/{filteredDn.total} )
              </Title>
            </div>
          </Grid>
          <Grid item xs={12}>
            {filteredDn.open_dn.map((d) => {
              return renderDnCard(d, handleOpen)
            })}
          </Grid>
        </Grid>

        <Grid item xs>
          <Grid item xs={12}>
            <div style={{ textAlign: "center" }}>
              <Title>
                Loading ({filteredDn.loading_dn.length}/{filteredDn.total})
              </Title>
            </div>
          </Grid>
          <Grid item xs={12}>
            {filteredDn.loading_dn.map((d) => {
              return renderDnCard(d, handleOpen)
            })}
          </Grid>
        </Grid>

        <Grid item xs>
          <Grid item xs={12}>
            <div style={{ textAlign: "center" }}>
              <Title>
                Loaded ({filteredDn.loaded_dn.length}/{filteredDn.total})
              </Title>
            </div>
          </Grid>
          <Grid item xs={12}>
            {filteredDn.loaded_dn.map((d) => {
              return renderDnCard(d, handleOpen)
            })}
          </Grid>
        </Grid>

        <Grid item xs>
          <Grid item xs={12}>
            <div style={{ textAlign: "center" }}>
              <Title>
                Discharge ({filteredDn.discharge_dn.length}/{filteredDn.total})
              </Title>
            </div>
          </Grid>
          <Grid item xs={12}>
            {filteredDn.discharge_dn.map((d) => {
              return renderDnCard(d, handleOpen)
            })}
          </Grid>
        </Grid>

        <Grid item xs>
          <Grid item xs={12}>
            <div style={{ textAlign: "center" }}>
              <Title>
                Closed ({filteredDn.discharged_dn.length}/{filteredDn.total})
              </Title>
            </div>
          </Grid>
          <Grid item xs={12}>
            {filteredDn.discharged_dn.forEach((d) => {
              if (!("yard_time" in d)) {
                return renderDnCard(d, handleOpen)
              }
            })}
            {filteredDn.discharged_dn.forEach((d) => {
              if ("yard_time" in d) {
                return renderDnCard(d, handleOpen)
              }
            })}
          </Grid>
        </Grid>
      </Grid>
      <DialogMap
        open={openDialog}
        location={location}
        data={data}
        handleClose={handleClose}
      />
    </React.Fragment>
  )
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const DialogMap = (props) => {
  const { open, location, data, handleClose } = props

  return (
    <Dialog fullScreen open={open} TransitionComponent={Transition}>
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">
            {data ? data.vehicleName : ""}
            {data.current_dn ? " - " + data.current_dn.dn : ""}
          </Typography>
        </Toolbar>
      </AppBar>
      {data && <TruckMap location={location} data={data} />}
    </Dialog>
  )
}
