import React, { useRef, useEffect, useState } from "react"
import { Timeline } from "vis-timeline"
import { DataSet } from "vis-data"
//import moment from 'moment'
//import axios from 'axios'
import AppApi from "../utils/AppApi"

const TruckView = () => {
  const [groupArr, setGroupArr] = useState([])

  const [itemArr, setItemArr] = useState([])

  // Create a ref to provide DOM access
  const visJsRef = useRef(null)
  useEffect(() => {
    const getDn = async () => {
      try {
        let response = await AppApi.get("/all_dn")

        //หา Group ทำ Distinc
        let all_dn = [...response.data]

        let all_group = all_dn.reduce((acc, current) => {
          const isExist = acc.find((item) => item.content === current.truck_no)

          if (!isExist) {
            let new_group = {
              id: current.truck_no.substring(1, current.truck_no.length),
              content: current.truck_no,
            }

            return acc.concat([new_group])
          } else {
            return acc
          }
        }, [])

        let all_item = []

        all_dn.forEach((item) => {
          let date_arr = []

          if ("arrival_loading_time" in item) {
            date_arr.push(new Date(item.arrival_loading_time))
          }
          if ("at_loading_time" in item) {
            date_arr.push(new Date(item.at_loading_time))
          }
          if ("departed_loading_time" in item) {
            date_arr.push(new Date(item.departed_loading_time))
          }

          item.discharges.forEach((dc) => {
            if ("arriving_discharge_time" in dc) {
              date_arr.push(new Date(dc.arriving_discharge_time))
            }
            if ("at_discharge_time" in dc) {
              date_arr.push(new Date(dc.at_discharge_time))
            }
            if ("departed_discharge_time" in dc) {
              date_arr.push(new Date(dc.departed_discharge_time))
            }
          })

          const sortDate = date_arr.sort((a, b) => a - b)

          if (sortDate.length === 0) {
            return
          }

          let start_date = sortDate.length > 0 ? sortDate[0] : new Date()
          let end_date =
            sortDate.length > 0 ? sortDate[sortDate.length - 1] : new Date()

          all_item.push({
            id: item.dn,
            group: item.truck_no.substring(1, item.truck_no.length),
            content: item.dn,
            start: start_date,
            end: end_date,
            type: "range",
          })
        })

        console.log("all group", all_group)
        console.log("all item", all_item)
        setGroupArr(all_group)
        setItemArr(all_item)
      } catch (error) {
        console.log(error.message)
      }
    }

    getDn()
  }, [])

  useEffect(() => {
    if (groupArr.length > 0 && itemArr.length > 0) {
      var options = {
        width: "100%",
        height: "700px",
        horizontalScroll: true,
        groupOrder: "content", // groupOrder can be a property name or a sorting function
      }
      let items = new DataSet(itemArr)
      let groups = new DataSet(groupArr)

      // const timeline =
      //   visJsRef.current &&
      new Timeline(visJsRef.current, items, groups, options)
      // Once the ref is created, we'll be able to use vis
    }
  }, [visJsRef, itemArr, groupArr])
  return (
    <div style={{ margin: "1px", padding: "5px", overflowY: "scroll" }}>
      <div ref={visJsRef} />
    </div>
  )
}

export default TruckView
