import React, { useState, useEffect } from "react"
//import axios from 'axios'
import moment from "moment"

import { styled } from "@mui/material/styles"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Grid"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Chip from "@mui/material/Chip"
import { Link, Button, TextField, ButtonGroup } from "@mui/material"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import DateTimePicker from "@mui/lab/DateTimePicker"
import FilterIcon from "@mui/icons-material/Sort"
import TelIcon from "@mui/icons-material/Call"

import AppApi from "../utils/AppApi"
import TruckMapDialog from "../components/TruckMapDialog"
import EtaHistory from "../components/EtaHistory"

const MA_polygon = [
  [99.57290053367615, 11.210460138532039],
  [99.57228899002075, 11.209439296466764],
  [99.57385540008545, 11.208797321775435],
  [99.57420945167542, 11.209344578978993],
  [99.57617282867432, 11.210039173168662],
  [99.57646250724792, 11.210460138532039],
  [99.57506775856018, 11.211217874641322],
  [99.5740807056427, 11.209965504167053],
  [99.57290053367615, 11.210460138532039],
]

const YARD_polygon = [
  [99.542795419693, 11.23023429536016],
  [99.54397559165955, 11.22913985906864],
  [99.54516649246214, 11.230023827164912],
  [99.54412579536438, 11.23131820413465],
  [99.542795419693, 11.23023429536016],
]

const TruckInYard = () => {
  const [data, setData] = useState([])
  const [eta, setETA] = useState([])
  const [ma, setMA] = useState([])
  const [value, setValue] = React.useState("1")
  const [openDialog, setOpenDialog] = useState(false)
  const [locationData, setLocationData] = useState({ vehicleName: "Dummy" })
  const [location] = useState([100, 100])
  const [currentETA, setCurrentETA] = useState(new Date())
  const [available, setAvailable] = useState(0)
  const [unavailable, setUnavailable] = useState(0)

  useEffect(() => {
    const getData = async () => {
      let response = await AppApi.post("/truck_polygon", {
        polygon: YARD_polygon,
      })

      setData(response.data)

      //update available stat
      let truck_avilable = response.data.filter(
        (truck) => truck.dn[0].status === "discharged"
      ).length

      let truck_unavailable = response.data.filter(
        (truck) => truck.dn[0].status !== "discharged"
      ).length

      setAvailable(truck_avilable)
      setUnavailable(truck_unavailable)

      console.log(moment(currentETA).format())

      let eta = await AppApi.post("/eta", {
        eta_date: moment(currentETA).format(),
      })
      setETA(eta.data)

      let ma_res = await AppApi.post("/truck_polygon", { polygon: MA_polygon })

      setMA(ma_res.data)
    }

    getData()
    const iv = setInterval(getData, 60000)

    return () => clearInterval(iv)
  }, [currentETA])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleClose = () => {
    console.log("handle Close")
    setOpenDialog(false)
  }

  const handleOpen = async (data) => {
    console.log("handle Open", data.truck_no)

    if (typeof data.current_dn === "string") {
      var dn_data = await AppApi.get(`/dn/${data.current_dn}`)
    }

    let response = await AppApi.post("/truck_location", {
      truck_no: data.truck_no,
    })

    let start_date = moment(
      data.current_dn.substring(0, 6),
      "YYMMDD"
    ).toISOString()
    let end_date = moment(data.current_dn.substring(0, 6), "YYMMDD")
      .add(3, "days")
      .toISOString()

    console.log(start_date, end_date)

    let history_data = await AppApi.post("/truck_history", {
      start_date,
      end_date,
      truck_no: data.truck_no,
    })

    let latitude = response.data[0].latitude
    let longitude = response.data[0].longitude
    let address = response.data[0].address
    let speed = response.data[0].speed
    let show_dn =
      response.data[0].dn.length > 10 ? 10 : response.data[0].dn.length
    let dn_list = response.data[0].dn.slice(-1 * show_dn)

    setLocationData({
      vehicleName: data.truck_no,
      latitude,
      longitude,
      address,
      dn_list,
      speed,
      history_data: history_data.data,
      current_dn:
        typeof data.current_dn === "string" ? dn_data.data[0] : data.current_dn,
    })
    setOpenDialog(true)
  }

  return (
    <div>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="menu tabs">
              <Tab label="ETA RealTime" value="1" />
              <Tab label="ETA History" value="2" />
              <Tab label={`Available Truck In Yard (${available})`} value="3" />
              <Tab
                label={`Not Available Truck In Yard (${unavailable})`}
                value="4"
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            <EtaBoard
              eta={eta}
              ma={ma}
              yard={data}
              handleOpen={handleOpen}
              viewDate={currentETA}
              changeViewDate={setCurrentETA}
            />
          </TabPanel>
          <TabPanel value="2">
            <EtaHistory />
          </TabPanel>
          <TabPanel value="3">
            <Grid container spacing={2}>
              <YardBoard
                data={data.filter(
                  (truck) => truck.dn[0].status === "discharged"
                )}
              />
            </Grid>
          </TabPanel>
          <TabPanel value="4">
            <Grid container spacing={2}>
              <YardBoard
                data={data.filter(
                  (truck) => truck.dn[0].status !== "discharged"
                )}
              />
            </Grid>
          </TabPanel>
        </TabContext>
      </Box>
      <TruckMapDialog
        open={openDialog}
        location={location}
        data={locationData}
        handleClose={handleClose}
      />
    </div>
  )
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}))

const YardBoard = (props) => {
  const { data } = props

  let sorted_data = data.sort((a, b) => a.dn[0].dn - b.dn[0].dn)

  console.log("data", data)

  if (data.length === 0) {
    return <></>
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>No</StyledTableCell>
              <StyledTableCell>Truck No</StyledTableCell>
              <StyledTableCell>Lastest DN</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Idle</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sorted_data.map((truck, index) => (
              <StyledTableRow>
                <StyledTableCell>{index + 1}</StyledTableCell>
                <StyledTableCell>{truck.truck_no}</StyledTableCell>
                <StyledTableCell> {truck.dn[0].dn}</StyledTableCell>
                <StyledTableCell>
                  {truck.dn[0].status.toUpperCase()}
                </StyledTableCell>
                <StyledTableCell>
                  {moment(truck.dn[0].dn.substring(0, 6), "YYMMDD").fromNow()}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

const EtaBoard = (props) => {
  const { eta, yard, ma, handleOpen, viewDate, changeViewDate } = props
  const [data, setData] = useState({ eta: [] })
  const [filter, setFilter] = useState([])

  useEffect(() => {
    var new_data = { eta: [], total_ma: 0 }
    var total_ma = 0
    var total_yard = 0
    var total_delay = 0
    var total_unconfirm = 0

    eta.forEach((truck) => {
      //ma status
      let ma_status =
        ma.findIndex((item) => item.truck_no === truck.truck_no) > -1
          ? true
          : false
      if (ma_status) {
        total_ma++
      }

      //yard status
      let yard_status =
        yard.findIndex((item) => item.truck_no === truck.truck_no) > -1
      if (yard_status) {
        total_yard++
      }

      //delay status
      let diff_min = moment().diff(moment(truck.eta), "minute") //moment(truck.eta).diff(moment(), 'minute')
      if (diff_min > 0 && !yard_status && !ma_status) {
        total_delay++
      }
      if (diff_min <= 0 && !yard_status && !ma_status) {
        total_unconfirm++
      }

      let new_row = {
        ...truck,
        ma_status,
        yard_status,
        delay_min: diff_min > 0 && !yard_status && !ma_status ? diff_min : 0,
      }

      new_data.eta.push(new_row)
    })

    new_data.total_ma = total_ma
    new_data.total_yard = total_yard
    new_data.total_delay = total_delay
    new_data.total_unconfirm = total_unconfirm

    setData({ ...new_data })
  }, [eta, yard, ma])

  const renderStat = (bgcolor, label, value, filter_code) => {
    return (
      <Grid item xs={12} md={6} lg={3}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 120,
            bgcolor,
            color: "white",
          }}
        >
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Typography variant="h4" gutterBottom component="div">
                {label}
              </Typography>
            </Grid>
            <Grid item>
              <FilterIcon
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  let new_filter = [...filter]
                  let index = new_filter.findIndex((f) => f === filter_code)
                  if (index === -1) {
                    new_filter.push(filter_code)
                    setFilter(new_filter)
                  } else {
                    new_filter.splice(index, 1)
                    setFilter(new_filter)
                  }
                }}
              />
            </Grid>
          </Grid>

          <Typography
            variant="h2"
            gutterBottom
            component="div"
            align="right"
            fontWeight="bold"
          >
            {value}
          </Typography>
        </Paper>
      </Grid>
    )
  }

  const renderRow = (row) => {
    let row_color = row.yard_status
      ? "success.main"
      : row.ma_status
      ? "text.disabled"
      : row.delay_min > 0
      ? "error.main"
      : "#0288d1"

    let row_status = row.yard_status
      ? "YARD"
      : row.ma_status
      ? "MA"
      : row.delay_min > 0
      ? "DELAY"
      : "OTW"

    let diff_day =
      row.yard_reason === "YARD_BSP" ||
      row.yard_reason === "YARD_MA" ||
      row.yard_reason === "NEW_DN_START"
        ? moment(moment(row.yard_time).format("YYYY-MM-DD")).diff(
            moment(moment(row.eta).format("YYYY-MM-DD")),
            "days"
          )
        : 0

    let ata_time =
      row.yard_reason === "YARD_BSP" ||
      row.yard_reason === "YARD_MA" ||
      row.yard_reason === "NEW_DN_START"
        ? moment(row.yard_time).format("HH:mm")
        : ""

    let gps_diff = moment().diff(moment(row.gps_update), "minute")

    let isShow =
      filter.length === 0
        ? true
        : filter.findIndex((item) => item === row_status) >= 0
        ? true
        : false

    return (
      <TableRow
        key={row.truck_no}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          color: row_color,
          display: isShow ? "" : "none",
        }}
      >
        <TableCell component="th" scope="row" sx={{ color: "inherit" }}>
          {moment(row.eta).format("DD-MM HH:mm")}
        </TableCell>
        <TableCell component="th" scope="row" sx={{ color: "inherit" }}>
          {ata_time}{" "}
          {diff_day !== 0
            ? diff_day > 0
              ? "(+" + diff_day + ")"
              : "(" + diff_day + ")"
            : ""}
        </TableCell>
        <TableCell sx={{ color: "inherit", textDecoration: "none" }}>
          <Link
            component="div"
            underline="none"
            color="inherit"
            onClick={() => {
              handleOpen({ truck_no: row.truck_no, current_dn: row.dn })
            }}
          >
            <Chip
              label={row.truck_no}
              sx={{
                backgroundColor: row_color,
                color: "white",
                cursor: "pointer",
                fontWeight: 700,
              }}
            />
          </Link>
        </TableCell>
        <TableCell sx={{ color: "inherit" }}>{row.dn}</TableCell>
        <TableCell sx={{ color: "inherit" }}>{row.driver}</TableCell>
        <TableCell sx={{ color: "inherit" }}>
          <TelIcon /> {row.tel}
        </TableCell>
        <TableCell sx={{ color: "inherit" }}>
          {!(row.yard_status || row.ma_status) ? row.address : ""}
          {row.yard_status ? "YARDL" : ""}
          {row.ma_status ? "ซ่อมบำรุง" : ""}
        </TableCell>
        <TableCell sx={{ color: "inherit" }}>
          {gps_diff > 180
            ? "GPS Problem"
            : row.delay_min > 0 && ata_time === ""
            ? moment.duration(row.delay_min, "minute").humanize()
            : ""}
        </TableCell>
      </TableRow>
    )
  }

  const renderFilter = () => {
    return (
      <Grid container direction="row" spacing={1}>
        <Grid item>
          <b>Filter :</b>
        </Grid>
        {filter.length === 0 && (
          <Grid item>
            <Chip label="Show All" />
          </Grid>
        )}
        {filter.map((f) => {
          return (
            <Grid item>
              <Chip
                label={f}
                onDelete={() => {
                  let index = filter.findIndex((item) => item === f)
                  let new_filter = [...filter]

                  new_filter.splice(index, 1)
                  setFilter(new_filter)
                }}
              />
            </Grid>
          )
        })}
      </Grid>
    )
  }

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            spacing={1}
          >
            <Grid item>
              <h2>Estimate Time Arival</h2>
              {renderFilter()}
            </Grid>
            <Grid item>
              <ButtonGroup
                variant="outlined"
                aria-label="outlined primary button group"
                sx={{ m: 1 }}
              >
                <Button
                  onClick={() => {
                    changeViewDate(
                      moment(
                        moment().utc().add(7, "hour").format("YYYY-MM-DD") +
                          "T10:00+07:00"
                      )
                    )
                  }}
                >
                  TODAY <br /> 10:00
                </Button>
                <Button
                  onClick={() => {
                    changeViewDate(
                      moment(
                        moment().utc().add(7, "hour").format("YYYY-MM-DD") +
                          "T18:00+07:00"
                      )
                    )
                  }}
                >
                  TODAY
                  <br /> 18:00
                </Button>
                <Button
                  onClick={() => {
                    changeViewDate(
                      moment(
                        moment()
                          .utc()
                          .add(7, "hour")
                          .add(1, "day")
                          .format("YYYY-MM-DD") + "T10:00+07:00"
                      )
                    )
                  }}
                >
                  TOMOROW <br />
                  10:00
                </Button>
                <Button
                  onClick={() => {
                    changeViewDate(
                      moment(
                        moment()
                          .utc()
                          .add(7, "hour")
                          .add(1, "day")
                          .format("YYYY-MM-DD") + "T18:00+07:00"
                      )
                    )
                  }}
                >
                  TOMOROW
                  <br /> 18:00
                </Button>
              </ButtonGroup>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label="Select Cut-Off Date Time"
                  value={viewDate}
                  onChange={changeViewDate}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ m: 1 }} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>
        {renderStat("success.main", "In Yard", data.total_yard, "YARD")}
        {renderStat("info.main", "On The Way", data.total_unconfirm, "OTW")}
        {renderStat("error.main", "Delayed", data.total_delay, "DELAY")}
        {renderStat("text.disabled", "In MA Yard", data.total_ma, "MA")}

        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>ETA</TableCell>
                  <TableCell>ATA</TableCell>
                  <TableCell>Truck</TableCell>
                  <TableCell>Last DN</TableCell>
                  <TableCell>Driver</TableCell>
                  <TableCell>Tel</TableCell>
                  <TableCell>Current Location</TableCell>
                  <TableCell>Delay</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.eta
                  .sort((a, b) => moment(a.eta) - moment(b.eta))
                  .map((row) => renderRow(row))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  )
}

// const renderTruckCard = (truck) => {
//   return (
//     <Grid item key={truck.truck_no} xs={12} lg={3}>
//       <Paper
//         sx={{
//           p: 2,
//           display: "flex",
//           flexDirection: "column",
//           height: 120,
//         }}
//         elevation={3}
//       >
//         <Grid container>
//           <Grid item xs={6}>
//             <Typography
//               variant="h2"
//               component="div"
//               fontWeight="bold"
//               color="primary"
//             >
//               {truck.truck_no}
//             </Typography>
//           </Grid>
//           <Grid item xs={6}>
//             <Typography
//               variant="h5"
//               align="right"
//               component="div"
//               color="GrayText"
//             >
//               {truck.dn[0].dn}
//             </Typography>
//             <Typography
//               variant="h5"
//               align="right"
//               component="div"
//               color="Highlight"
//             >
//               {truck.dn[0].status === "discharged"
//                 ? moment(truck.dn[0].dn.substring(0, 6), "YYMMDD").fromNow()
//                 : truck.dn[0].status}
//             </Typography>
//           </Grid>
//           <Grid item xs={12}></Grid>
//         </Grid>
//       </Paper>
//     </Grid>
//   )
// }

export default TruckInYard
