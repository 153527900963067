import React, { useState, useEffect } from "react"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import Grid from "@mui/material/Grid"
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material"
import moment from "moment"
import DatePicker from "@mui/lab/DatePicker"
//import axios from 'axios'

import AppApi from "../utils/AppApi"

const EtaHistory = (props) => {
  const [viewDate, setViewDate] = useState(new Date())
  const [data, setData] = useState([])

  useEffect(() => {
    const getData = async () => {
      let response = await AppApi.post("/eta_history", { view_date: viewDate })

      setData(response.data)
      //console.log(data)
    }

    getData()
  }, [viewDate])

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            spacing={1}
          >
            <Grid item>
              <h2>ETA History</h2>
            </Grid>
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Select ETA Date"
                  value={viewDate}
                  onChange={setViewDate}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ m: 1 }} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>ETA</TableCell>
                  <TableCell>ATA</TableCell>
                  <TableCell align="center">Delay</TableCell>
                  <TableCell>Truck</TableCell>
                  <TableCell>DN</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((dn) => {
                  return (
                    <TableRow>
                      <TableCell>
                        <b>{moment(dn.eta).local().format("HH:mm")}</b>
                      </TableCell>
                      <TableCell>
                        {dn.yard_time === undefined
                          ? ""
                          : moment(dn.yard_time).local().format("DD/MM HH:mm")}
                      </TableCell>
                      <TableCell align="center">
                        {dn.yard_time === undefined ? (
                          ""
                        ) : moment(dn.eta) < moment(dn.yard_time) ? (
                          <Chip
                            color="error"
                            label={
                              moment(dn.yard_time).diff(
                                moment(dn.eta),
                                "hours"
                              ) + " Hours"
                            }
                          />
                        ) : (
                          <Chip
                            variant="outlined"
                            color="success"
                            label="Ontime"
                          />
                        )}
                      </TableCell>
                      <TableCell>{dn.truck_no}</TableCell>
                      <TableCell>{dn.dn}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  )
}

export default EtaHistory
