import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
//import axios from 'axios'
import Dialog from "@material-ui/core/Dialog"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import CloseIcon from "@material-ui/icons/Close"
import Slide from "@material-ui/core/Slide"
import Button from "@material-ui/core/Button"
import Map from "../components/Map"
import AppApi from "../utils/AppApi"

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function TruckList(props) {
  const classes = useStyles()
  const [trucks, setTrucks] = useState([])
  const [data, setData] = useState({})
  const [open, setOpen] = useState(false)
  const [currentView, setCurrentView] = useState("")
  const [location] = useState([13.638468, 100.557651])

  const handleClickOpen = (data) => {
    setCurrentView(data.vehicleName)
    setData(data)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    const getTrucks = async () => {
      try {
        let response = await AppApi.get("/truck_location")
        setTrucks(response.data)
      } catch (error) {
        console.log(error.message)
      }
    }

    getTrucks()

    const interval = setInterval(() => {
      getTrucks()
    }, 60000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (currentView !== "") {
      setData(
        trucks.filter((t) => {
          return t.vehicleName === currentView
        })[0]
      )
    }
  }, [trucks, currentView])

  return (
    <div>
      {console.log(data)}
      {trucks
        .sort((a, b) =>
          a.vehicleName > b.vehicleName
            ? 1
            : b.vehicleName > a.vehicleName
            ? -1
            : 0
        )
        .map((truck) => (
          <li key={truck.vehicleName}>
            {truck.vehicleName} - {truck.speed} - {truck.address}{" "}
            <Button onClick={() => handleClickOpen(truck)}>Map</Button>
          </li>
        ))}

      <Dialog fullScreen open={open} TransitionComponent={Transition}>
        <AppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Truck Location - {data.vehicleName}
            </Typography>
          </Toolbar>
        </AppBar>
        <Map location={location} data={data} />
      </Dialog>
    </div>
  )
}
