import { useEffect, useState } from "react"
import TextField from "@mui/material/TextField"
import CircularProgress from "@mui/material/CircularProgress"
//import Container from "@mui/material/Container"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import AppApi from "../utils/AppApi"
import moment from "moment"

const ReportDriver = () => {
  const [data, setData] = useState([])
  const [value, setValue] = useState(new Date())
  const [isLoading, setIsLoading] = useState(false)

  //const startOfMonth = parseInt(moment(value).startOf('month').format('DD'))
  const endOfMonth = parseInt(moment(value).endOf("month").format("DD"))

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true)
      try {
        let result = await AppApi.post("/rpt_driver", {
          viewMonth: moment(value).format("YYMM"),
        })
        setIsLoading(false)
        setData(result.data)
      } catch (error) {
        setIsLoading(false)
        console.log(error.message)
      }
    }

    getData()
  }, [value])

  const renderRow = (d) => {
    let result = []

    let prv_dn = []

    for (let i = 0; i < endOfMonth; i++) {
      let continue_working = false
      let filter = d.history.filter(
        (h) =>
          h.dn.substring(0, 6) ===
          moment(value).startOf("month").add(i, "day").format("YYMMDD")
      )

      if (prv_dn.length > 0 && filter.length > 0) {
        //กรณีมี 1 งานต่อวัน
        if (prv_dn.length === 1 && filter.length === 1) {
          continue_working =
            prv_dn[0].driver === filter[0].driver &&
            prv_dn[0].job_type === "F" &&
            filter[0].job_type === "F"
              ? true
              : false
        }
        // กรณีวันปัจจุบัน มีมากกว่า 1 งาน
        if (filter.length > 1) {
          for (let f of filter) {
            let prv_index = prv_dn.findIndex((p) => p.driver === f.driver)

            if (prv_index >= 0) {
              if (f.job_type === "F" && prv_dn[prv_index].job_type === "F") {
                continue_working = true
              }
            }
          }
        }
      }

      prv_dn = filter

      if (filter.length > 0) {
        result.push(
          <td
            key={i}
            style={{
              textAlign: "center",
              border: "1px solid black",
              color: `${continue_working ? "red" : "green"}`,
            }}
          >
            {filter.map((f) => {
              return (
                <div key={f.dn}>
                  {f.driver.split(" ")[0]} [{f.job_type}]
                </div>
              )
            })}
          </td>
        )
      } else {
        result.push(
          <td
            key={i}
            style={{
              textAlign: "center",
              border: "1px solid black",
            }}
          >
            -
          </td>
        )
      }
    }
    return result
  }

  return (
    <div
      style={{
        overflowX: "scroll",
        overflowY: "scroll",
        height: "93vh",
        paddingLeft: "5px",
        paddingTop: "20px",
      }}
    >
      <div>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            views={["year", "month"]}
            label="Year and Month"
            minDate={new Date("2022-02-01")}
            maxDate={new Date("2050-12-31")}
            value={value}
            onChange={(newValue) => {
              setValue(newValue)
            }}
            renderInput={(params) => (
              <TextField {...params} helperText={null} />
            )}
          />
          {isLoading && <CircularProgress style={{ marginLeft: "10px" }} />}
        </LocalizationProvider>
      </div>
      <h1>Report Driver</h1>
      <p>
        F=Front Haul, B=Back Haul , S=Short Range(Same Zone) , L=Back Haul Long
        Range
      </p>
      <table>
        <thead style={{ position: "sticky", top: 0, zIndex: "1" }}>
          <tr style={{ backgroundColor: "white" }}>
            <td
              style={{
                position: "sticky",
                left: 0,
                zIndex: 1,
                backgroundColor: "white",
              }}
            >
              Truck
            </td>
            {[...Array(endOfMonth)].map((x, i) => {
              return (
                <td
                  style={{
                    minWidth: "100px",
                    textAlign: "center",
                    border: "1px solid black",
                  }}
                >
                  {i + 1}
                </td>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((d) => {
            return (
              <tr key={d.truck_no}>
                <td
                  style={{
                    position: "sticky",
                    left: 0,
                    zIndex: 1,
                    backgroundColor: "white",
                  }}
                >
                  {d.truck_no}
                </td>
                {renderRow(d)}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default ReportDriver
