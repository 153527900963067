import { useEffect, useState } from 'react'
import AppApi from '../utils/AppApi'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Container from '@mui/material/Container'

const ReportTraccar = () => {
  const [data, setData] = useState([])

  useEffect(() => {
    const getData = async () => {
      let result = await AppApi.get('/traccar')

      console.log(result.data)
      setData(result.data)
    }

    getData()

    const interval = setInterval(() => {
      getData()
    }, 30000)

    return () => clearInterval(interval)
  }, [])

  return (
    <Container maxWidth="sm" sx={{ mt: 2 }}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Truck No</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="right">Diff Distance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(
              (d, i) =>
                d.traccar_status === 'online' && (
                  <TableRow
                    key={d.truck_no}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{d.truck_no}</TableCell>
                    <TableCell>{d.traccar_status}</TableCell>
                    <TableCell align="right">{d.distance}</TableCell>
                  </TableRow>
                )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  )
}

export default ReportTraccar
