import React, { useState, useEffect, useRef } from "react"
import { renderToStaticMarkup } from "react-dom/server"
import moment from "moment"
import AppApi from "../utils/AppApi"
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  LayersControl,
  LayerGroup,
  useMap,
} from "react-leaflet"
import { divIcon } from "leaflet"
import ReactLeafletDriftMarker from "react-leaflet-drift-marker"

import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch"
import "leaflet-geosearch/dist/geosearch.css"

import icon from "../components/constants"

const LeafletgeoSearch = () => {
  const map = useMap()
  useEffect(() => {
    //const provider = new EsriProvider()
    const provider = new OpenStreetMapProvider({
      params: {
        "accept-language": "th",
        countrycodes: "th",
        addressdetails: 1, // include additional address detail parts
      },
    })

    const searchControl = new GeoSearchControl({
      provider,
      marker: {
        icon,
      },
    })

    map.addControl(searchControl)

    return () => map.removeControl(searchControl)
  }, [map])

  return null
}

const MapView = () => {
  const [trucks, setTrucks] = useState([])
  const [sinoTrucks, setSinoTrucks] = useState([])
  const [renderTrucks, setRenderTrcuk] = useState([])
  const [poi, setPoi] = useState([])

  const trucksRef = useRef(trucks)

  useEffect(() => {
    trucksRef.current = trucks
  })

  useEffect(() => {
    const getTrucks = async () => {
      try {
        let response = await AppApi.get("/truck_location")

        setTrucks(response.data)

        setRenderTrcuk(response.data)
      } catch (error) {
        console.log(error.message)
      }
    }

    const getSinoTrucks = async () => {
      let sino_res = await AppApi.get("/sinotrack")
      setSinoTrucks(sino_res.data)
    }

    getTrucks()
    getSinoTrucks()
    const interval = setInterval(() => {
      getTrucks()
    }, 60000)
    const iv2 = setInterval(() => {
      getSinoTrucks()
    }, 10000)

    return () => {
      clearInterval(interval)
      clearInterval(iv2)
    }
  }, [])

  useEffect(() => {
    const getPoi = async () => {
      let start_date = moment().subtract(3, "days").format("YYMMDD")

      try {
        let response = await AppApi.post("/poi_dn", {
          start_date,
        })

        let final_poi = []

        response.data.forEach((poi) => {
          let dataIndex = final_poi.findIndex((d) => d._id === poi._id)

          //console.log('index', dataIndex)

          if (dataIndex === -1) {
            final_poi.push(poi)
          } else {
            //console.log(poi._id, final_poi[dataIndex])
            final_poi[dataIndex].dn = [...final_poi[dataIndex].dn, ...poi.dn]
          }
        })

        setPoi(final_poi)
      } catch (error) {
        console.log(error.message)
      }
    }

    return () => getPoi()
    // const interval2 = setInterval(() => {
    //   getPoi()
    // }, 60000)

    // return () => clearInterval(interval2)
  }, [trucks])

  useEffect(() => {
    const destinationPoint = (lat, lon, distance, bearing) => {
      var radius = 6371e3 // (Mean) radius of earth

      var toRadians = function (v) {
        return (v * Math.PI) / 180
      }
      var toDegrees = function (v) {
        return (v * 180) / Math.PI
      }

      // sinφ2 = sinφ1·cosδ + cosφ1·sinδ·cosθ
      // tanΔλ = sinθ·sinδ·cosφ1 / cosδ−sinφ1·sinφ2
      // see mathforum.org/library/drmath/view/52049.html for derivation

      var δ = Number(distance) / radius // angular distance in radians
      var θ = toRadians(Number(bearing))

      var φ1 = toRadians(Number(lat))
      var λ1 = toRadians(Number(lon))

      var sinφ1 = Math.sin(φ1),
        cosφ1 = Math.cos(φ1)
      var sinδ = Math.sin(δ),
        cosδ = Math.cos(δ)
      var sinθ = Math.sin(θ),
        cosθ = Math.cos(θ)

      var sinφ2 = sinφ1 * cosδ + cosφ1 * sinδ * cosθ
      var φ2 = Math.asin(sinφ2)
      var y = sinθ * sinδ * cosφ1
      var x = cosδ - sinφ1 * sinφ2
      var λ2 = λ1 + Math.atan2(y, x)

      return [toDegrees(φ2), ((toDegrees(λ2) + 540) % 360) - 180] // normalise to −180..+180°
    }

    console.log("Get New Trcuks Data")
    var snapshot_trucks = [...trucks]

    const iv = setInterval(() => {
      let updated_trucks = []

      //let snapshot_trucks = [...trucks]

      console.log("snapshot_trucks", snapshot_trucks.length)

      snapshot_trucks.forEach((truck) => {
        let lat = truck.latitude
        let lng = truck.longitude
        let distance = (truck.speed * 0.8 * 1000) / 3600

        let new_location = destinationPoint(lat, lng, distance, truck.direction)

        updated_trucks.push({
          ...truck,
          latitude: new_location[0],
          longitude: new_location[1],
        })
      })

      //console.log(updated_trucks)
      setRenderTrcuk(updated_trucks)
      snapshot_trucks = [...updated_trucks]
    }, 1000)

    return () => clearInterval(iv)
  }, [trucks])

  // const customMarkerIcon = divIcon({
  //   html: renderToStaticMarkup(<i className="fas fa-truck fa-2x" />),
  // })

  var pv_date = ""

  return (
    <MapContainer
      center={[13.638468, 100.557651]}
      zoom={12}
      style={{ height: "95vh" }}
    >
      <LeafletgeoSearch />
      <LayersControl position="topright">
        <LayersControl.BaseLayer checked name="OpenStreetMap.Mapnik">
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Esri.WorldImagery">
          <TileLayer
            maxZoom={18}
            attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Google.Map">
          <TileLayer
            maxZoom={20}
            subdomains={["mt0", "mt1", "mt2", "mt3"]}
            attribution="Tiles &copy; Google Map"
            url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
          />
        </LayersControl.BaseLayer>

        <LayersControl.BaseLayer name="Google.Hybrid">
          <TileLayer
            maxZoom={20}
            subdomains={["mt0", "mt1", "mt2", "mt3"]}
            attribution="Tiles &copy; Google Map"
            url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
          />
        </LayersControl.BaseLayer>
        <LayersControl.Overlay checked name="Loading Poi">
          <LayerGroup>
            {poi.map((p) => {
              //console.log(p._id)
              const icon = divIcon({
                html: renderToStaticMarkup(
                  <div>
                    <i
                      className="fas fa-map-marker-alt fa-2x"
                      style={{
                        color: "blue",
                        textShadow: "-1px -1px 0px black",
                      }}
                    ></i>
                    <div className="marker_label">
                      <span>{p._id}</span>
                    </div>
                  </div>
                ),
              })

              if (p.lat === null || p.lng === null) {
                return null
              }

              return (
                <Marker position={[p.lat, p.lng]} icon={icon} key={p._id}>
                  <Popup>
                    <b>({p._id})</b> {p.name}
                    <br />
                    {p.address}
                    <br />
                    {p.lat},{p.lng}
                    <hr />
                    <div className="popup_list">
                      {p.dn.length > 0
                        ? p.dn.map((d, i) => {
                            //ถ้าเป็น Record แรกไม่ต้องขีดเส้น
                            if (i === 0) {
                              pv_date = d.dn.substring(0, 6)
                              return (
                                <li key={d.dn}>
                                  {d.dn} - {d.truck_no} - {d.loading_point}{" "}
                                  <i className="fas fa-arrow-right"></i>{" "}
                                  {Array.isArray(d.discharges) &&
                                    d.discharges.map(
                                      (dis) => dis.discharge_point + " "
                                    )}
                                  {!Array.isArray(d.discharges) &&
                                    d.discharges.discharge_point}{" "}
                                  - {d.status}
                                </li>
                              )
                            }

                            if (pv_date === d.dn.substring(0, 6)) {
                              pv_date = d.dn.substring(0, 6)
                              return (
                                <li key={d.dn}>
                                  {d.dn} - {d.truck_no} - {d.loading_point}{" "}
                                  <i className="fas fa-arrow-right"></i>{" "}
                                  {Array.isArray(d.discharges) &&
                                    d.discharges.map(
                                      (dis) => dis.discharge_point + " "
                                    )}
                                  {!Array.isArray(d.discharges) &&
                                    d.discharges.discharge_point}{" "}
                                  - {d.status}
                                </li>
                              )
                            } else {
                              pv_date = d.dn.substring(0, 6)
                              return (
                                <React.Fragment>
                                  <hr />
                                  <li key={d.dn}>
                                    {d.dn} - {d.truck_no} - {d.loading_point}{" "}
                                    <i className="fas fa-arrow-right"></i>{" "}
                                    {Array.isArray(d.discharges) &&
                                      d.discharges.map(
                                        (dis) => dis.discharge_point + " "
                                      )}
                                    {!Array.isArray(d.discharges) &&
                                      d.discharges.discharge_point}{" "}
                                    - {d.status}
                                  </li>
                                </React.Fragment>
                              )
                            }
                          })
                        : null}
                    </div>
                  </Popup>
                </Marker>
              )
            })}
          </LayerGroup>
        </LayersControl.Overlay>
        <LayersControl.Overlay checked name="Truck">
          <LayerGroup>
            {renderTrucks.map((t) => {
              let dn_list = [...t.lastest_dn]
              let dn_list_rev = [...t.lastest_dn].reverse()
              let isShowRoute =
                dn_list[0].status !== "discharged" ||
                parseInt(dn_list[0].dn.substring(0, 6)) >=
                  parseInt(moment().subtract(3, "days").format("YYMMDD"))
              let truck_icon =
                dn_list[0].status === "discharged"
                  ? "fas fa-truck"
                  : "fas fa-shipping-fast"
              let icon = divIcon({
                html: renderToStaticMarkup(
                  <div
                    key={t.vehicleName}
                    style={{
                      color:
                        t.evEngine === 0
                          ? "red"
                          : t.speed > 0
                          ? "green"
                          : "orange",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "16px",

                        textShadow: "-1px -1px 3px black",
                      }}
                    >
                      {t.direction > 180 ? (
                        <i
                          className={truck_icon}
                          style={{
                            transform: `rotate(${
                              t.direction - 90
                            }deg) scaleY(-1)`,
                          }}
                        />
                      ) : (
                        <i
                          className={truck_icon}
                          style={{
                            transform: `rotate(${t.direction - 90}deg)`,
                          }}
                        />
                      )}
                    </div>

                    <div className="marker_label">
                      <div>{t.vehicleName}</div>

                      {isShowRoute && (
                        <div style={{ fontSize: "0.6rem", fontWeight: 600 }}>
                          {dn_list_rev[dn_list_rev.length - 1].loading_point}
                          {dn_list_rev[dn_list_rev.length - 1].discharges.map(
                            (d) => (
                              <span key={d.discharge_point}>
                                {" "}
                                - {d.discharge_point}
                              </span>
                            )
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                ),
              })
              return (
                <ReactLeafletDriftMarker
                  position={[t.latitude, t.longitude]}
                  icon={icon}
                  duration={1000}
                  key={t.vehicleName}
                >
                  <Popup>
                    <div>
                      <b>
                        {t.vehicleName} - {t.speed} km/h
                      </b>
                    </div>
                    <div>
                      {dn_list[0].driver} - {dn_list[0].tel}
                    </div>
                    <div>{t.address}</div>
                    <div>
                      {t.latitude},{t.longitude}
                    </div>

                    <hr />
                    {dn_list_rev.map((d) => (
                      <li key={d.dn}>
                        {d.dn} - {d.loading_point}{" "}
                        <i className="fas fa-arrow-right"></i>{" "}
                        {d.discharges.map((dis) => dis.discharge_point + " ")} -{" "}
                        <span
                          style={{
                            color: d.status === "discharged" ? "green" : "red",
                          }}
                        >
                          {d.status}
                        </span>
                      </li>
                    ))}
                  </Popup>
                </ReactLeafletDriftMarker>
              )
            })}
          </LayerGroup>
        </LayersControl.Overlay>
        <LayersControl.Overlay checked name="Sinotrack">
          <LayerGroup>
            {sinoTrucks.map((t) => {
              let truck_icon =
                [...t.lastest_dn][0]?.status === "discharged"
                  ? "fas fa-truck"
                  : "fas fa-shipping-fast"
              let dn_list_rev = [...t.lastest_dn].reverse()

              let icon = divIcon({
                html: renderToStaticMarkup(
                  <div
                    key={t.strTEID}
                    style={{
                      color: "#FF00FF",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "16px",

                        textShadow: "-1px -1px 3px black",
                      }}
                    >
                      {t.nDirection > 180 ? (
                        <i
                          className={truck_icon}
                          style={{
                            transform: `rotate(${
                              t.nDirection - 90
                            }deg) scaleY(-1)`,
                          }}
                        />
                      ) : (
                        <i
                          className={truck_icon}
                          style={{
                            transform: `rotate(${t.nDirection - 90}deg)`,
                          }}
                        />
                      )}
                    </div>

                    <div className="marker_label">
                      <div>
                        {t.strTEID} <br /> BT: {parseInt(t.nTEState / 65535)}{" "}
                        SP:{t.nSpeed} <br />{" "}
                        {dn_list_rev[dn_list_rev.length - 1]?.license_plate}
                      </div>
                      <div style={{ fontSize: "0.6rem", fontWeight: 600 }}>
                        {dn_list_rev[dn_list_rev.length - 1]?.loading_point}
                        {dn_list_rev[dn_list_rev.length - 1]?.discharges.map(
                          (d) => (
                            <span key={d.discharge_point}>
                              {" "}
                              - {d.discharge_point}
                            </span>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                ),
              })
              return (
                <ReactLeafletDriftMarker
                  position={[t.dbLat, t.dbLon]}
                  icon={icon}
                  duration={10000}
                  key={t.strTEID}
                >
                  <Popup>
                    <div>
                      <b>
                        {t.strTEID} - {t.nSpeed} km/h
                      </b>
                    </div>
                    <div>{[...t.lastest_dn][0]?.license_plate}</div>
                    <div>
                      {[...t.lastest_dn][0]?.driver} -{" "}
                      {[...t.lastest_dn][0]?.tel}
                    </div>
                    <div>Batt: {parseInt(t.nTEState / 65535)} %</div>
                    <hr />
                    {[...t.lastest_dn].reverse().map((d) => (
                      <li key={d.dn}>
                        {d.dn} - {d.loading_point}{" "}
                        <i className="fas fa-arrow-right"></i>{" "}
                        {d.discharges.map((dis) => dis.discharge_point + " ")} -{" "}
                        <span
                          style={{
                            color: d.status === "discharged" ? "green" : "red",
                          }}
                        >
                          {d.status}
                        </span>
                      </li>
                    ))}
                  </Popup>
                </ReactLeafletDriftMarker>
              )
            })}
          </LayerGroup>
        </LayersControl.Overlay>
      </LayersControl>
    </MapContainer>
  )
}

export default MapView
