import React from "react"
import { renderToStaticMarkup } from "react-dom/server"
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
  LayersControl,
  Tooltip,
  GeoJSON,
} from "react-leaflet"
import { divIcon } from "leaflet"
import { useSelector } from "react-redux"
//import DnBoard from './DnBoard'

export default function TruckMap(props) {
  const user_data = useSelector((state) => state.auth.user)
  const position = props.data.latitude
    ? [props.data.latitude, props.data.longitude]
    : [13.638468, 100.557651]

  const {
    vehicleName,
    address,
    dn_list,
    speed,
    current_dn,
    history_data,
    dn_detail,
  } = props.data
  const polyline = []

  //let current_dn = { ...dn_list[dn_list.length - 1] }

  polyline.push([current_dn.loading_lat, current_dn.loading_lng])
  current_dn.discharges.forEach((dc) => {
    polyline.push([dc.discharge_lat, dc.discharge_lng])
  })
  const limeOptions = { color: "lime" }

  const FactoryMarker = (props) => (
    <Marker
      position={props.location}
      icon={divIcon({
        html: renderToStaticMarkup(
          <div>
            <i
              className="fas fa-map-marker-alt fa-2x"
              style={{
                color: "blue",
                textShadow: "-1px -1px 0px black",
              }}
            ></i>
            <div className="marker_label">
              <span>{props.point}</span>
            </div>
          </div>
        ),
      })}
    >
      <Tooltip direction="top" offset={[10, -10]} opacity={1} permanent>
        <div>
          <b>
            {props.data.point} Point - {props.point}{" "}
          </b>
          <br />
          <hr />
          <b>{props.data.name}</b>
          <br />
          {props.data.addr} <br />
          {props.location[0]},{props.location[1]}
        </div>
      </Tooltip>
    </Marker>
  )

  const TruckMarker = () => (
    <Marker
      position={position}
      icon={divIcon({
        html: renderToStaticMarkup(
          <div>
            <i
              className="fas fa-truck fa-2x"
              style={{
                color: "green",
                textShadow: "-1px -1px 0px black",
              }}
            ></i>
            <div className="marker_label">{vehicleName}</div>
          </div>
        ),
      })}
    >
      <Popup>
        <div style={{ fontSize: "16px" }}>
          <b>
            {vehicleName} - {current_dn.license_plate} - {speed} KM/H
          </b>
        </div>
        <hr />
        <div>
          พขร. {current_dn.driver} - Tel {current_dn.tel}
        </div>
        <div>{address}</div>
        <div>
          <div
            style={{
              backgroundColor: "black",
              color: "white",
              alignItems: "center",
              padding: "2px",
              marginTop: "2px",
              marginBottom: "2px",
            }}
          >
            <b>Product Detail | DN {current_dn.dn}</b>
          </div>
          <div>
            <table>
              <thead style={{ fontWeight: "bold" }}>
                <tr>
                  <td>No</td>
                  <td>Product</td>
                  <td>Coil No</td>
                  <td>Thick</td>
                  <td>Width</td>
                  <td>Weight</td>
                </tr>
              </thead>
              <tbody>
                {dn_detail.map((d, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td> {d.product}</td>
                      <td>{d.coil_no}</td>
                      <td style={{ textAlign: "right" }}>{d.thk}</td>
                      <td style={{ textAlign: "right" }}>{d.wid}</td>
                      <td style={{ textAlign: "right" }}>{d.wgt}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
        {/* Filter Out Truck Log from guest user */}

        {user_data.roles[0] === "Admin" && (
          <React.Fragment>
            <div
              style={{
                backgroundColor: "black",
                color: "white",
                alignItems: "center",
                padding: "2px",
                marginTop: "5px",
                marginBottom: "5px",
              }}
            >
              <b>Truck History</b>
            </div>
            <div>
              <table width="100%">
                <thead style={{ fontWeight: "bold" }}>
                  <tr>
                    <td>DN</td>
                    <td style={{ flex: "1" }}>Route</td>
                    <td>Status</td>
                  </tr>
                </thead>
                <tbody>
                  {dn_list.map((d) => {
                    return (
                      <tr key={d.dn}>
                        <td>{d.dn}</td>
                        <td>
                          {d.loading_point + " "}
                          <i className="fas fa-arrow-right" />{" "}
                          {d.discharges.map((dc) => dc.discharge_point + " ")}
                        </td>
                        <td>{d.status}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </React.Fragment>
        )}
      </Popup>
    </Marker>
  )

  return (
    <div className="map__container">
      <MapContainer center={position} zoom={11} style={{ height: "100vh" }}>
        <LayersControl position="bottomright">
          <LayersControl.BaseLayer checked name="OpenStreetMap.Mapnik">
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Esri.WorldImagery">
            <TileLayer
              maxZoom={18}
              attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Google.Map">
            <TileLayer
              maxZoom={20}
              subdomains={["mt0", "mt1", "mt2", "mt3"]}
              attribution="Tiles &copy; Google Map"
              url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
            />
          </LayersControl.BaseLayer>

          <LayersControl.BaseLayer name="Google.Hybrid">
            <TileLayer
              maxZoom={20}
              subdomains={["mt0", "mt1", "mt2", "mt3"]}
              attribution="Tiles &copy; Google Map"
              url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
            />
          </LayersControl.BaseLayer>
        </LayersControl>
        <Polyline pathOptions={limeOptions} positions={polyline} />
        <GeoJSON data={history_data} />
        <FactoryMarker
          location={[current_dn.loading_lat, current_dn.loading_lng]}
          point={current_dn.loading_point}
          data={{
            name: current_dn.loading_name,
            addr: current_dn.loading_addr,
            point: "Loading",
          }}
        />
        {current_dn.discharges.map((dc) => (
          <FactoryMarker
            location={[dc.discharge_lat, dc.discharge_lng]}
            point={dc.discharge_point}
            data={{
              name: dc.discharge_name,
              addr: dc.discharge_addr,
              point: "Discharge",
            }}
            key={dc.discharge_point}
          />
        ))}
        <TruckMarker />
      </MapContainer>
    </div>
  )
}
