import axios from "axios"
import { refreshToken, setError } from "../reducers/authSlice"

let store

export const injectStore = (_store) => {
  store = _store
}
// import { setError } from '../reducers/authSlice'

const baseURL =
  process.env.NODE_ENV !== "production"
    ? "http://localhost:3000/api"
    : "https://tms.line.co.th/api"

const AppApi = axios.create({
  baseURL,
})

export const AppAuthApi = axios.create({
  baseURL,
})

AppApi.interceptors.request.use((config) => {
  config.headers.common["x-auth-token"] = store.getState().auth.token
  return config
})

AppApi.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    const originalRequest = error.config
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true
      try {
        const token = await refreshAccessToken()
        store.dispatch(refreshToken({ token }))
        //AppApi.defaults.headers.common['x-auth-token'] = token
        delete originalRequest.headers["x-auth-token"]
        return AppApi(originalRequest)
      } catch (err) {
        store.dispatch(setError("Token is Expired"))
        localStorage.clear()
        window.location.href = "/auth/login"
      }
    }

    return Promise.reject(error)
  }
)

async function refreshAccessToken() {
  return new Promise((resolve, reject) => {
    const refreshToken = store.getState().auth.refreshToken
    axios
      .patch(
        `${baseURL}/auth/token`,
        {},
        {
          headers: {
            "x-auth-token": refreshToken,
          },
        }
      )
      .then((res) => {
        resolve(res.data.accessToken)
      })
      .catch((err) => {
        reject("Some thing went wrong")
      })
  })
}

export default AppApi
