import React, { useState, useEffect } from "react"

import Grid from "@mui/material/Grid"
import PropTypes from "prop-types"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import Chip from "@mui/material/Chip"
import Link from "@mui/material/Link"
//import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
//import Switch from '@mui/material/Switch'
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import OutlinedInput from "@mui/material/OutlinedInput"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import { grey, blue } from "@mui/material/colors"
import DatePicker from "@mui/lab/DatePicker"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"

import { useSelector } from "react-redux"

import TruckMapDialog from "../components/TruckMapDialog"
import AppApi from "../utils/AppApi"

//Other Utility
//import axios from 'axios'
import _ from "lodash"
import moment from "moment"
import { Container, Zoom } from "@mui/material"
//import { bgcolor } from '@mui/system'
//import DateFnsUtils from '@date-io/date-fns'

//zimport { isInYardLH } from "../utils/GisService"

const boxBgColor = grey[400]
const textColor = grey[800]
const textPrimaryColor = blue[800]
const textGreyColor = grey[600]
const timeBoxStyle = {
  borderRightStyle: "solid",
  borderColor: "#bdbdbd",
  borderWidth: "2px",
  paddingLeft: "2px",
  paddingRight: "2px",
  minWidth: "3.75rem",
  fontWeight: "600",
}

const noBorderStyle = {
  paddingLeft: "2px",
  paddingRight: "2px",
  minWidth: "3.75rem",
  fontWeight: "600",
}

function Item(props) {
  const { sx, ...other } = props
  return (
    <Box
      sx={{
        bgcolor: boxBgColor,
        color: textColor,
        p: 0.5,
        m: 0.5,
        borderRadius: 1,
        textAlign: "left",
        fontSize: "1rem",
        fontWeight: "700",
        ...sx,
      }}
      {...other}
    />
  )
}

Item.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
}

const ItemCard = React.forwardRef((props, ref) => {
  const { sx, data, handleOpen, ...other } = props

  const convert_time = (dn, str_time) => {
    if (!str_time) {
      return <center>-</center>
    }

    let dn_date = moment(dn.substring(0, 6), "YYMMDD")
    let diff_day = moment(
      moment(str_time).utc().add(7, "hours").format("YYYY-MM-DD") +
        "T00:00:00+07:00"
    ).diff(dn_date, "days")

    // console.log(
    //   dn,
    //   str_time,
    //   dn_date.format('DD-MM-YYYY'),
    //   moment(str_time).utc().add(7, 'hours').format('DD-MM-YYYY'),
    //   diff_day
    // )

    return diff_day > 0
      ? moment(str_time).utc().add(7, "hours").format("HH:mm") + "+" + diff_day
      : moment(str_time).utc().add(7, "hours").format("HH:mm")
  }

  let isPartner = data.truck_owner !== "LTC"

  return data ? (
    <Box
      sx={{
        maxWidth: "331px",
        bgcolor: "white",
        m: 0.25,
        p: 0.5,
        borderRadius: 1,
        ...sx,
      }}
      {...other}
      key={data.dn}
      ref={ref}
    >
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box sx={{ m: 0.3, minWidth: "4rem" }}>
          <Box
            sx={{
              fontSize: "1.7rem",
              color: textPrimaryColor,
              textAlign: "center",
            }}
          >
            <Link
              underline="none"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                handleOpen({ truck_no: data.truck_no, current_dn: data })
              }}
            >
              {isPartner ? data.truck_owner : data.truck_no}
            </Link>
          </Box>
          <Box sx={{ fontSize: "0.7rem", textAlign: "center" }}>
            {"license_plate" in data ? data.license_plate : data.status}
          </Box>
          {/* <Box sx={{ fontSize: '0.7rem', textAlign: 'center' }}>
            {data.status}
          </Box> */}
        </Box>
        <Box sx={{ fontSize: "0.9rem", width: "100%" }}>
          <Box
            sx={{
              fontSize: "1.1rem",
              color: textGreyColor,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div style={{ flexGrow: 1 }}>{data.dn}</div>
            {data.yard_reason ? (
              ""
            ) : data.distance ? (
              <div>{`${Math.ceil(data.distance / 1000)} KM`}</div>
            ) : (
              ""
            )}
            {data.yard_reason ? (
              <Chip
                size="small"
                color={
                  data.yard_reason.includes("NEW") ? "secondary" : "success"
                }
                label={data.yard_reason.replaceAll("_", " ")}
              />
            ) : (
              ""
            )}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                color: "red",
                borderRightStyle: "solid",
                borderColor: "#bdbdbd",
                borderWidth: "2px",
                paddingLeft: "2px",
                paddingRight: "2px",
                minWidth: "3.1rem",
              }}
            >
              {data.loading_point}{" "}
            </div>
            {data.status !== "open" ? (
              <>
                <div style={timeBoxStyle}>
                  {convert_time(data.dn, data.arrival_loading_time)}
                </div>
                <div style={timeBoxStyle}>
                  {convert_time(data.dn, data.at_loading_time)}
                </div>
                <div style={noBorderStyle}>
                  {convert_time(data.dn, data.departed_loading_time)}
                </div>
              </>
            ) : data.loading_lat === "" ? (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  color: "Red",
                }}
              >
                No GPS Location
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  color: "orange",
                }}
              >
                {data.duration < 4 ? (
                  "Truck Arriving...."
                ) : (
                  <>
                    ETA :
                    {convert_time(
                      data.dn,
                      moment()
                        .add(
                          data.duration > 240
                            ? data.duration + 30
                            : data.duration,
                          "minute"
                        )
                        .toISOString()
                    )}
                  </>
                )}
              </div>
            )}
          </Box>
          {data.discharges.map((dc) => (
            <Box
              sx={{ display: "flex", flexDirection: "row" }}
              key={dc.discharge_point}
            >
              <div
                style={{
                  color: "green",
                  borderRightStyle: "solid",
                  borderColor: "#bdbdbd",
                  borderWidth: "2px",
                  paddingLeft: "2px",
                  paddingRight: "2px",
                  minWidth: "3.1rem",
                }}
              >
                {dc.discharge_point}{" "}
              </div>
              {data.status === "closed" ? (
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    color: "red",
                  }}
                >
                  No Data Available
                </div>
              ) : data.status !== "loaded" || dc.arriving_discharge_time ? (
                <>
                  <div style={timeBoxStyle}>
                    {convert_time(data.dn, dc.arriving_discharge_time)}
                  </div>
                  <div style={timeBoxStyle}>
                    {convert_time(data.dn, dc.at_discharge_time)}
                  </div>
                  <div style={noBorderStyle}>
                    {convert_time(data.dn, dc.departed_discharge_time)}
                  </div>
                </>
              ) : (
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    color: "orange",
                  }}
                >
                  ETA :{" "}
                  {convert_time(
                    data.dn,
                    moment()
                      .add(
                        data.duration > 240
                          ? data.duration + 30
                          : data.duration,
                        "minute"
                      )
                      .toISOString()
                  )}
                </div>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  ) : (
    ""
  )
})

// ItemCard.propTypes = {
//   sx: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
//   data: PropTypes.object,
// }

export default function DnTrack() {
  const [dn, setDn] = useState([])
  const [truckNo, setTruckNo] = useState("")
  const [viewDate, setViewDate] = useState(new Date())
  const [filteredDn, setFilteredDn] = useState({
    total: 0,
    open_dn: [],
    loading_dn: [],
    loaded_dn: [],
    discharge_dn: [],
    discharged_dn: [],
  })
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState("")
  const [discharge, setDischarge] = useState("")
  //const [data, setData] = useState({ vehicleName: "Dummy" })
  const [dnRoute, setDnRoute] = useState([])
  const [filterRoute, setFilterRoute] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [locationData, setLocationData] = useState({ vehicleName: "Dummy" })
  const [location] = useState([100, 100])
  const [isSortTime, setIsSortTime] = useState(false)

  const user_data = useSelector((state) => state.auth.user)

  useEffect(() => {
    const getDn = async () => {
      setIsLoading(true)
      try {
        let response = await AppApi.post("/dn_status", {
          start_date: moment(viewDate).format("DD/MM/YYYY"),
          end_date: moment(viewDate).format("DD/MM/YYYY"),
          permission: user_data.permission,
        })

        let calculated_dn = await Promise.all(
          response.data.map(async (dn) => {
            let total_time = 0
            let isPartner = dn.truck_owner !== "LTC"

            if (dn.yard_reason) {
              let start_time = dn.arrival_loading_time
                ? dn.arrival_loading_time
                : dn.at_loading_time
                ? dn.at_loading_time
                : dn.departed_loading_time
                ? dn.departed_loading_time
                : 0

              if (start_time !== 0) {
                total_time = moment(dn.yard_time).diff(
                  moment(start_time),
                  "hours"
                )
              }
            }

            let new_dn = {
              ...dn,
              total_time,
            }

            try {
              if (dn.status === "loaded") {
                const res = await AppApi.post("/distance", {
                  truck_no: isPartner ? dn.mid : dn.truck_no,
                  lat: dn.discharges[0].discharge_lat,
                  lng: dn.discharges[0].discharge_lng,
                })

                new_dn = {
                  ...new_dn,
                  distance: res.data.distance,
                  duration: res.data.duration,
                }
              }

              if (dn.status === "open") {
                const res = await AppApi.post("/distance", {
                  truck_no: isPartner ? dn.mid : dn.truck_no,
                  lat: dn.loading_lat,
                  lng: dn.loading_lng,
                })

                new_dn = {
                  ...new_dn,
                  distance: res.data.distance,
                  duration: res.data.duration,
                }
              }
            } catch (error) {
              console.log(error)
            }

            return new_dn
          })
        )

        //Filter DN Permission By Customer

        let permission_dn =
          typeof user_data.permission !== "undefined"
            ? calculated_dn.filter(
                (dn) => dn.customer.toUpperCase() === user_data.permission[0]
              )
            : calculated_dn

        //console.log('cal', calculated_dn)
        setDn(response.data.length !== 0 ? permission_dn : [])
        //setFilteredDn(response.data)
        //setIsLoading(false)
      } catch (error) {
        console.log(error.message)
        setIsLoading(false)
      }
    }

    getDn()

    const interval = setInterval(() => {
      getDn()
    }, 60000)

    return () => clearInterval(interval)
  }, [viewDate, user_data])

  useEffect(() => {
    // Create Route Data //
    // route = [{ code , loading , [discharge] , [dn]}]
    var truck_route = []
    dn.forEach((d) => {
      let current_code =
        d.loading_point + d.discharges.map((dc) => "-" + dc.discharge_point)
      current_code = current_code.replaceAll(",", "")
      let index = truck_route.findIndex((r) => {
        return r.code === current_code
      })

      if (index > -1) {
        truck_route[index].dn_list.push(d.dn)
      } else {
        truck_route.push({
          code: current_code,
          loading: d.loading_point,
          discharges: [d.discharges.map((dc) => dc.discharge_point)],
          dn_list: [d.dn],
        })
      }
    })

    //console.log(truck_route)
    setDnRoute(truck_route.sort((a, b) => ("" + a.code).localeCompare(b.code)))
  }, [dn])

  useEffect(() => {
    if (dn.length !== 0) {
      //console.log('DN Change')
      let result = [...dn]
      //let isFilter = false

      if (truckNo.length !== 0) {
        //isFilter = true
        result = _.filter(result, (d) => _.includes(d.truck_no, truckNo))
      }

      if (loading.length >= 3) {
        console.log("In Loading")
        //isFilter = true
        result = _.filter(result, (d) => _.includes(d.loading_point, loading))
      }

      if (discharge.length >= 3) {
        console.log("In Discharge")
        console.log(result)
        //isFilter = true
        result = _.filter(result, (d) => {
          let found = _.find(
            d.discharges,
            (dc) => dc.discharge_point === discharge
          )
          if (found !== undefined) {
            return true
          } else {
            return false
          }
        })
        console.log("after", result)
      }

      if (filterRoute.length > 0) {
        let filter_dn_list = []
        filterRoute.forEach((fr) => {
          let index = dnRoute.findIndex((r) => r.code === fr)
          filter_dn_list = [...filter_dn_list, ...dnRoute[index].dn_list]
        })

        console.log("AAAA", filter_dn_list)
        result = result.filter(({ dn }) => filter_dn_list.includes(dn))
      }

      let open_dn = []
      let loading_dn = []
      let loaded_dn = []
      let discharge_dn = []
      let discharged_dn = []

      result.forEach((r) => {
        //New Status for auto close DN
        if (r.status === "closed") {
          discharged_dn.push(r)
          return
        }

        //check discharge first

        let arr_discharged = r.discharges.filter(
          (d) => d.discharge_status === 2
        )

        if (arr_discharged.length > 0) {
          discharged_dn.push(r)
          return
        }

        let arr_discharge = r.discharges.filter((d) => {
          return d.discharge_status >= 0 && d.discharge_status <= 1
        })
        if (arr_discharge.length > 0) {
          discharge_dn.push(r)
          return
        }

        if (r.loading_status === -1) {
          open_dn.push(r)
          return
        }

        if (r.loading_status === 2) {
          loaded_dn.push(r)
          return
        }

        loading_dn.push(r)
      })

      let finish_dn = {
        total: result.length,
        open_dn,
        loading_dn,
        loaded_dn,
        discharge_dn,
        discharged_dn,
      }

      //console.log(finish_dn)
      if (isSortTime) {
        let sorted_dn = {
          total: finish_dn.total,
          open_dn: finish_dn.open_dn,
          loading_dn: finish_dn.loading_dn.sort(
            (a, b) =>
              moment(a.arrival_loading_time) - moment(b.arrival_loading_time)
          ),
          loaded_dn: finish_dn.loaded_dn.sort(
            (a, b) =>
              moment(a.departed_loading_time) - moment(b.departed_loading_time)
          ),
          discharge_dn: finish_dn.discharge_dn.sort((a, b) => {
            // หา max discharge ของ a และ b
            let max_dc_a = null
            let max_dc_b = null

            a.discharges.forEach((dc) => {
              if (max_dc_a) {
                if (typeof dc.arriving_discharge_time !== "undefined") {
                  max_dc_a =
                    max_dc_a >= moment(dc.arriving_discharge_time).unix()
                      ? max_dc_a
                      : moment(dc.arriving_discharge_time).unix()
                }
              } else {
                if (typeof dc.arriving_discharge_time !== "undefined") {
                  max_dc_a = moment(dc.arriving_discharge_time).unix()
                }
              }
            })

            b.discharges.forEach((dc) => {
              if (max_dc_b) {
                if (typeof dc.arriving_discharge_time !== "undefined") {
                  max_dc_b =
                    max_dc_b >= moment(dc.arriving_discharge_time).unix()
                      ? max_dc_b
                      : moment(dc.arriving_discharge_time).unix()
                }
              } else {
                if (typeof dc.arriving_discharge_time !== "undefined") {
                  max_dc_b = moment(dc.arriving_discharge_time).unix()
                }
              }
            })

            return max_dc_a - max_dc_b

            // //////////
            // if (
            //   typeof a.discharges[0].arriving_discharge_time !== 'undefined'
            // ) {
            //   return (
            //     moment(a.discharges[0].arriving_discharge_time) -
            //     moment(b.discharges[0].arriving_discharge_time)
            //   )
            // } else {
            //   return (
            //     moment(a.discharges[1].arriving_discharge_time) -
            //     moment(b.discharges[1].arriving_discharge_time)
            //   )
            // }
          }),
          discharged_dn: finish_dn.discharged_dn.sort((a, b) => {
            // หา max discharge ของ a และ b
            let max_dc_a = null
            let max_dc_b = null

            a.discharges.forEach((dc) => {
              if (max_dc_a) {
                if (typeof dc.departed_discharge_time !== "undefined") {
                  max_dc_a =
                    max_dc_a >= moment(dc.departed_discharge_time).unix()
                      ? max_dc_a
                      : moment(dc.departed_discharge_time).unix()
                }
              } else {
                if (typeof dc.departed_discharge_time !== "undefined") {
                  max_dc_a = moment(dc.departed_discharge_time).unix()
                }
              }
            })

            b.discharges.forEach((dc) => {
              if (max_dc_b) {
                if (typeof dc.departed_discharge_time !== "undefined") {
                  max_dc_b =
                    max_dc_b >= moment(dc.departed_discharge_time).unix()
                      ? max_dc_b
                      : moment(dc.departed_discharge_time).unix()
                }
              } else {
                if (typeof dc.departed_discharge_time !== "undefined") {
                  max_dc_b = moment(dc.departed_discharge_time).unix()
                }
              }
            })

            return max_dc_a - max_dc_b
          }),
        }
        console.log(sorted_dn)
        setFilteredDn(sorted_dn)
      } else {
        // No Time Sorting
        setFilteredDn(finish_dn)
      }
    } else {
      setFilteredDn({
        total: 0,
        open_dn: [],
        loading_dn: [],
        loaded_dn: [],
        discharge_dn: [],
        discharged_dn: [],
      })
    }

    setIsLoading(false)
  }, [dn, truckNo, loading, discharge, filterRoute, isSortTime, dnRoute])

  const handleDnRouteChange = (event) => {
    const {
      target: { value },
    } = event
    setFilterRoute(typeof value === "string" ? value.split(",") : value)
  }

  const handleClose = () => {
    console.log("handle Close")
    //setLocationData({ vehicleName: 'Dummy' })
    setOpenDialog(false)
  }

  const handleOpen = async (data) => {
    console.log("handle Open", data.truck_no)

    let isDnString = typeof data.current_dn === "string"

    if (isDnString) {
      var dn_data = await AppApi.get(`/dn/${data.current_dn}`)
    }

    let isPartner = !isDnString
      ? data.current_dn.truck_owner !== "LTC"
      : dn_data.data[0].truck_owner !== "LTC"

    let response = await AppApi.post("/truck_location", {
      truck_no: isPartner
        ? isDnString
          ? dn_data.data[0].mid
          : data.current_dn.mid
        : data.truck_no,
    })

    let dn_detail_res = await AppApi.get(
      `/dn_detail?dn_no=${isDnString ? dn_data.data[0].dn : data.current_dn.dn}`
    )

    let start_date = moment(
      moment(data.current_dn.dn.substring(0, 6), "YYMMDD").format(
        "YYYY-MM-DD"
      ) + "T00:00+07:00"
    ).toISOString()
    let end_date = moment(
      moment(data.current_dn.dn.substring(0, 6), "YYMMDD")
        .add(3, "days")
        .format("YYYY-MM-DD") + "T00:00+07:00"
    ).toISOString()

    let history_data = isPartner
      ? await AppApi.post("/sinotrack_history", {
          start_date,
          end_date,
          imei: data.current_dn.mid,
        })
      : await AppApi.post("/truck_history", {
          start_date,
          end_date,
          truck_no: data.truck_no,
        })

    let latitude = response.data[0].latitude
    let longitude = response.data[0].longitude
    let address = response.data[0].address
    let speed = response.data[0].speed
    let show_dn =
      response.data[0].dn.length > 10 ? 10 : response.data[0].dn.length
    let dn_list = response.data[0].dn.slice(-1 * show_dn)

    setLocationData({
      vehicleName: data.truck_no,
      latitude,
      longitude,
      address,
      dn_list,
      speed,
      history_data: history_data.data,
      dn_detail: dn_detail_res.data.queueDetail,
      current_dn:
        typeof data.current_dn === "string" ? dn_data.data[0] : data.current_dn,
    })
    setOpenDialog(true)
  }

  return (
    <Container maxWidth="xl">
      <Grid container spacing={3} padding={2} sx={{ maxWidth: "1412px" }}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item maxWidth="180px">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Select Date to view"
                  value={viewDate}
                  onChange={setViewDate}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item maxWidth="150px">
              <TextField
                size="small"
                id="truck-no-textbox"
                label="Truck No"
                value={truckNo}
                onChange={(e) => setTruckNo(e.target.value)}
              />
            </Grid>

            <Grid item maxWidth="150px">
              <TextField
                size="small"
                id="loading-textbox"
                label="Loading Point"
                value={loading}
                onChange={(e) => setLoading(e.target.value.toUpperCase())}
              />
            </Grid>

            <Grid item maxWidth="150px">
              <TextField
                size="small"
                id="discharge-textbox"
                label="Discharge Point"
                value={discharge}
                onChange={(e) => setDischarge(e.target.value.toUpperCase())}
              />
            </Grid>

            <Grid item>
              <FormControl sx={{ width: 300 }}>
                <InputLabel id="dn-route-label">DN Route</InputLabel>
                <Select
                  labelId="dn-route-label"
                  id="dn-route-chip"
                  multiple
                  value={filterRoute}
                  onChange={handleDnRouteChange}
                  input={
                    <OutlinedInput
                      id="select-multiple-chip"
                      label="DN Route"
                      size="small"
                    />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                >
                  {dnRoute
                    ? dnRoute.map((r) => (
                        <MenuItem key={r.code} value={r.code}>
                          <div style={{ flexGrow: 1 }}>{r.code}</div>
                          <Chip size="small" label={r.dn_list.length} />
                        </MenuItem>
                      ))
                    : ""}
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={isSortTime}
                    onChange={() => setIsSortTime(!isSortTime)}
                  />
                }
                label="Sort By Time"
              />
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setTruckNo("")
                  setLoading("")
                  setDischarge("")
                  setFilterRoute([])
                }}
              >
                Clear
              </Button>
            </Grid>

            {isLoading ? (
              <Grid item>
                <CircularProgress color="primary" />
                Loading...
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        {/* Show Column */}
        <Grid item xs={12}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                sm: "repeat(1, 1fr)",
                md: "repeat(2, 1fr)",
                lg: "repeat(4, 1fr)",
              },
            }}
          >
            <Item>
              <div style={{ marginBottom: "8px", fontSize: "1.5rem" }}>
                Open ({filteredDn.open_dn.length})
              </div>
              {filteredDn.open_dn.map((d) => (
                <Zoom in={true}>
                  <ItemCard key={d.dn} data={d} handleOpen={handleOpen} />
                </Zoom>
              ))}
            </Item>
            <Item>
              <div style={{ marginBottom: "8px", fontSize: "1.5rem" }}>
                Loading ({filteredDn.loading_dn.length})
              </div>
              {filteredDn.loading_dn.map((d) => (
                <Zoom in={true}>
                  <ItemCard key={d.dn} data={d} handleOpen={handleOpen} />
                </Zoom>
              ))}
            </Item>
            <Item>
              <div style={{ marginBottom: "8px", fontSize: "1.5rem" }}>
                On The Way ({filteredDn.loaded_dn.length})
              </div>
              {filteredDn.loaded_dn.map((d) => (
                <Zoom in={true}>
                  <ItemCard key={d.dn} data={d} handleOpen={handleOpen} />
                </Zoom>
              ))}
            </Item>
            <Item>
              <div style={{ marginBottom: "8px", fontSize: "1.5rem" }}>
                Discharging ({filteredDn.discharge_dn.length})
              </div>
              {filteredDn.discharge_dn.map((d) => (
                <Zoom in={true}>
                  <ItemCard key={d.dn} data={d} handleOpen={handleOpen} />
                </Zoom>
              ))}
            </Item>
          </Box>
          <Box>
            <Item>
              <div style={{ marginBottom: "8px", fontSize: "1.5rem" }}>
                Close ({filteredDn.discharged_dn.length})
              </div>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    xs: "repeat(1, 1fr)",
                    md: "repeat(2, 1fr)",
                    lg: "repeat(4, 1fr)",
                  },
                }}
              >
                {filteredDn.discharged_dn.map((d) => (
                  <Zoom in={true}>
                    <ItemCard key={d.dn} data={d} handleOpen={handleOpen} />
                  </Zoom>
                ))}
              </Box>
            </Item>
          </Box>
        </Grid>
      </Grid>
      <TruckMapDialog
        open={openDialog}
        location={location}
        data={locationData}
        handleClose={handleClose}
      />
    </Container>
  )
}
